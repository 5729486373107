<div [formGroup]="form">
  <div class="mt-3">
    <h3>Maine</h3>
  </div>
  <div [formGroupName]="0">
    <label for="q-0">
      {{ form.controls[0].value.question }}
    </label>
    <p-selectButton
      id="q-0"
      [options]="selectOptions"
      optionLabel="name"
      optionValue="value"
      [formControl]="form.controls[0]['controls'].answer"
    ></p-selectButton>
  </div>
</div>
