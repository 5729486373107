import {
  Directive,
  Optional,
  Inject,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  Host,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { merge, EMPTY, Observable, of, fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FORM_ERRORS } from '../../utils/form-errors';
import { FormSubmitDirective } from './form-submit.directive';
import { ControlErrorComponent } from '../components/control-error/control-error.component';
import { TriggerValidationService } from '../../services/trigger-validation.service';

@UntilDestroy()
@Directive({
  selector: '[formControl], [formControlName]',
})
export class ControlErrorsDirective {
  ref: ComponentRef<ControlErrorComponent> | null = null;
  container: ViewContainerRef;
  submit$: Observable<Event>;
  @Input() customErrors: { [key: string]: string } = {};
  suppressValidationMessage: boolean = true;

  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    @Inject(FORM_ERRORS)
    private errors: { [key: string]: (error: string | null) => string },
    @Optional() @Host() private form: FormSubmitDirective,
    private controlDir: NgControl,
    private _triggerValidation: TriggerValidationService
  ) {
    this.suppressValidationMessage =
      !!vcr.element.nativeElement.attributes.suppressValidationMessage;

    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  ngOnInit() {
    if (this.control && !this.suppressValidationMessage) {
      merge(
        this.control.valueChanges,
        this.control.statusChanges,
        this.submit$,
        this._triggerValidation.triggerValidation$
      )
        .pipe(untilDestroyed(this))
        .subscribe((v) => {
          const controlErrors = this.control?.errors;
          if (controlErrors) {
            const firstKey = Object.keys(controlErrors)[0];
            const getError = this.errors[firstKey];
            const text =
              this.customErrors[firstKey] || getError(controlErrors[firstKey]);
            this.setError(text);
          } else if (this.ref) {
            this.setError(null);
          }
        });
    }
  }

  get control() {
    return this.controlDir.control;
  }

  setError(text: string | null) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(
        ControlErrorComponent
      );
      const label =
        this.container.element.nativeElement.parentNode.querySelectorAll(
          'label'
        )[0];
      label.classList.add('label--error');
      this.ref = this.container.createComponent(factory);
    }

    this.ref.instance.text = text;
  }

  ngOnDestroy() {}
}
