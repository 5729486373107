import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EntityType } from '@types';

@Component({
  selector: 'app-alaska-corp-questions',
  templateUrl: './alaska-corp-questions.component.html',
  styleUrls: ['./alaska-corp-questions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AlaskaCorpQuestionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AlaskaCorpQuestionsComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class AlaskaCorpQuestionsComponent implements OnInit {
  @Input() entityType: EntityType;
  form = new FormGroup({
    0: new FormGroup({
      question: new FormControl(`Name and Address of Alien Affiliate`),
      answer: new FormControl(null, Validators.required),
    }),
    1: new FormGroup({
      question:
        new FormControl(`Name and address of each person/entity owning 5% or more of the issued shares or more
      of the issued shares or 5% of any class of issued shares`),
      answer: new FormArray([this.addressFormGroup()]),
    }),
  });
  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
    setTimeout(() => {
      this.form.updateValueAndValidity();
    }, 0);
  }

  addAdditionalAddress() {
    this.form.controls[1]['controls'].answer.push(this.addressFormGroup());
  }

  removeAdditionalAddress(index: number) {
    const answer = this.form.controls[1]['controls'].answer as FormArray;
    answer.removeAt(index);
  }

  addressFormGroup() {
    return new FormGroup({
      address: new FormControl(),
      percentageOwned: new FormControl('', Validators.required),
    });
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    // add addresses to form array.  needed if returning to step in stepper
    if (value && value[1]?.answer.length > 1) {
      value[1].answer.forEach((answer: any, index: number) => {
        if (index > 0) {
          this.form.controls[1]['controls'].answer.push(new FormControl());
        }
      });
    }
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { foreignRegistrationStates: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
