import { Component, OnInit } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from '@angular/fire/auth';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification.service';
import { setErrors } from 'src/app/utils/form-errors';
import { AnalyticsService } from '../../services/analytics.service';
const googleProvider = new GoogleAuthProvider();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  loading = false;

  headerMenuItems: MenuItem[] = [
    {
      title: 'Start My Business',
      routerLink: ['/register'],
      styleClass: 'p-button-outlined dense',
    },
    {
      title: 'Create Account',
      routerLink: ['/create-account'],
      styleClass: 'p-button-outlined dense',
    },
  ];

  constructor(
    public auth: Auth,
    private _router: Router,
    private _notificationService: NotificationService,
    private analytics: AnalyticsService
  ) {}
  

  ngOnInit() {
    const user = this.auth.currentUser;
    if (user) {
      this._router.navigate(['/dashboard']);
    }
    this.analytics.logEvent('login', {
      event_action: 'loaded',
      event_category: 'login',
      event_label: 'login loaded',
    });
    
  }

  async login() {
    if (this.loginForm.invalid) {
      return;
    }
    this.loginForm.disable();
    try {
      this.loading = true;
      const { email, password } = this.loginForm.value;
      await signInWithEmailAndPassword(this.auth, email, password);
      this._notificationService.showSuccessNotification('Welcome back!');
      this.loading = false;
      this.analytics.logEvent('login', {
        event_action: 'success',
        event_category: 'login',
        event_label: 'login success',
      });
      this._router.navigate(['/dashboard']);
    } catch (error: any) {
      this.loading = false;
      this._notificationService.showErrorNotification(
        'Login failed, please check your email and password'
      );
      this.loginForm.setErrors([]);
      this.analytics.logEvent('login', {
        event_action: 'fail',
        event_category: 'login',
        event_label: 'login fail',
      });
      this.loginForm.enable();
    }
  }

  googleSigninPopup() {
    this.loginForm.disable();
    signInWithPopup(this.auth, googleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        // The signed-in user info.
        const user = result.user;
        this._router.navigate(['/dashboard']);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        this.loginForm.enable();
        return { user: null, credential };
      });
  }
}
