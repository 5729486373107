import { Component, forwardRef, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-new-hampshire-corp-questions',
  templateUrl: './new-hampshire-corp-questions.component.html',
  styleUrls: ['./new-hampshire-corp-questions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewHampshireCorpQuestionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NewHampshireCorpQuestionsComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class NewHampshireCorpQuestionsComponent implements OnInit {
  form = new FormGroup({
    0: new FormGroup({
      question: new FormControl(null, Validators.required),
      answer: new FormControl(true),
    }),
    1: new FormControl(),
  });
  selected = new FormControl();
  options = [
    {
      question: `1. Ownership interests in this business are exempt from the registration
        requirements of the state of New Hampshire because the business meets all
        of the following three requirements:
        A) This business has 10 or fewer owners;
        and
        B) Advertising relating to the sale of ownership interests has not been circulated;
        and
        C) Sales of ownership interests - if any - will be completed within 60 days of the formation of this business.`,
    },
    {
      question: `2. This business will offer securities in New Hampshire under another exemption
        from registration or will notice file for federal covered securities.`,
      subQuestion: `Enter the citation for the exemption or notice filing claimed:`,
    },
    {
      question: `3. This business has registered or will register its securities for sale in New Hampshire`,
      subQuestion: `Enter the date the registration statement was or will be filed with the Bureau of Securities Regulation:`,
    },
    {
      question: `4. This business was formed in a state other than New Hampshire and will not offer or sell securities in New Hampshire`,
    },
  ];
  constructor() {}

  get control() {
    return this.form.controls[0] as FormControl;
  }

  get isQuestionThree() {
    return this.form.controls[0]['controls'].question?.value.includes('3');
  }
  ngOnInit(): void {
    const conditionallyRequiredValidator = (formGroup) => {
      // Sub-questions should be conditionally required
      if (
        formGroup.value[0].question === this.options[1].question ||
        formGroup.value[0].question === this.options[2].question
      ) {
        return formGroup.value[1]?.answer &&
          Validators.required(formGroup.value[1].answer)
          ? null
          : {
              conditionallyRequired: true,
            };
      }
      return null;
    };
    this.form.setValidators([conditionallyRequiredValidator]);
    this.form.updateValueAndValidity();

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
    setTimeout(() => {
      this.form.updateValueAndValidity();
    }, 0);
  }

  populateSubQuestion(question: string | undefined) {
    if (question) {
      this.form.setControl(
        '1',
        new FormGroup({
          question: new FormControl(question),
          answer: new FormControl(),
        })
      );
    } else {
      this.form.setControl('1', new FormControl());
    }
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    if (value && value[1]) {
      this.form.setControl(
        '1',
        new FormGroup({
          question: new FormControl(value[1].question),
          answer: new FormControl(value[1].answer),
        })
      );
    }
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { foreignRegistrationStates: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
