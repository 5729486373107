<div [formGroup]="form">
  <div>
    <h3>Wisconsin</h3>
  </div>
  <p-checkbox
    [formControl]="form.controls[0]['controls'].answer"
    [label]="form.controls[0].value.question"
    [binary]="true"
  ></p-checkbox>
</div>
