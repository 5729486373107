import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Title, Industry, Services } from '@types';
import { BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  states = new BehaviorSubject<State[]>([]);
  titles = new BehaviorSubject<Title[]>([]);
  industries = new BehaviorSubject<Industry[]>([]);
  services = new BehaviorSubject<Services[]>([]);
  constructor(private _httpClient: HttpClient) {}

  getStates() {
    return this._httpClient
      .get<State[]>(`${environment.apiUrl}/constants/states`)
      .pipe(tap((states) => this.states.next(states)));
  }

  getTitles() {
    return this._httpClient
      .get<Title[]>(`${environment.apiUrl}/constants/titles`)
      .pipe(tap((titles) => this.titles.next(titles)));
  }

  getIndustries() {
    return this._httpClient
      .get<Industry[]>(`${environment.apiUrl}/constants/industries`)
      .pipe(tap((industries) => this.industries.next(industries)));
  }

  getServices() {
    return this._httpClient
      .get<Services[]>(`${environment.apiUrl}/constants/services`)
      .pipe(tap((services) => this.services.next(services)));
  }
}
