<div [formGroup]="form">
  <div>
    <h3>Arkansas</h3>
  </div>
  <div [formGroupName]="0" class="mt-3">
    <label for="q-0">
      {{ form.controls[0].value.question }}
    </label>
    <input
      id="q-0"
      [formControl]="form.controls[0]['controls'].answer"
      type="number"
      pInputText
    />
  </div>
  <div [formGroupName]="1" class="mt-3">
    <label for="q-1">
      {{ form.controls[1].value.question }}
    </label>
    <input
      id="q-1"
      [formControl]="form.controls[1]['controls'].answer"
      type="number"
      pInputText
    />
  </div>
  <div [formGroupName]="2" class="mt-3">
    <p-checkbox
      [formControl]="form['controls'][2]['controls']['answer']"
      [binary]="true"
      [label]="form.controls[2].value.question"
    ></p-checkbox>
  </div>
  <p class="error-message">
    {{ form.errors ? "These fields are required (or check the box)." : "" }}
  </p>
</div>
