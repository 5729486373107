<div [formGroup]="form">
  <div class="mt-3">
    <h3>New York</h3>
  </div>
  <div [formGroupName]="0">
    <label for="q-0">
      {{ form.controls[0].value.question }}
    </label>
    <input
      [formControl]="form.controls[0]['controls'].answer"
      type="text"
      pInputText
    />
  </div>
  <div class="mt-3">
    *Entity is not required to have an office location in the state, but a
    county within New York must be provided.
  </div>
</div>
