<app-header *ngIf="!isCurrentUser" [rightMenuConfig]="headerMenuItems"></app-header>
<app-landing-wrapper [modalExperience]="false" *ngIf="showPage">
  <div sidebar>
    <h3 class="mt-1">Add Registration</h3>
    <p-progressBar [showValue]="false" [value]="progress"></p-progressBar>
    <span class="mt-1 block text-sm">{{ progress }}% Complete</span>
    <ol class="mt-5 pl-0">
      <li
        *ngFor="let step of steps; let i = index"
        class="mb-3 p-2"
        [ngClass]="{
          'active-step': currentStep === step.key,
          'disabled-button': !touchedSteps[step.key]
        }"
        (click)="jumpToStep(step.key)"
      >
        {{ i + 1 }}.
        {{ step.value }}

        <i
          *ngIf="touchedSteps[step.key] && form.controls[step.key]?.valid; else stepHasError"
          class="pi pi-check ml-1"
        ></i>
        <ng-template #stepHasError>
          <i *ngIf="touchedSteps[step.key] && currentStep !== step.key" class="pi pi-times"></i>
        </ng-template>
      </li>
    </ol>
  </div>
  <div content>
    <div class="mobile-progress">
      <h3 class="mt-1 text-bold">Existing Business Registration</h3>
      <p-progressBar [showValue]="false" [value]="progress"></p-progressBar>
      <span class="mt-1 block text-sm"
        ><span class="text-bold">Step {{ currentStepIndex + 1 }} of 8:</span> {{ progress }}%
        Complete</span
      >
    </div>
    <div [formGroup]="form">
      <div [ngSwitch]="currentStep" [smoothHeight]="currentStepIndex" class="steps--container">
        <div *ngSwitchCase="'existingBusinessDetails'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Existing Business Entity Details</h2>
              <p>To set you up in our system, we need some details about your existing business.</p>
              <app-existing-name-and-type
                formControlName="existingBusinessDetails"
                suppressValidationMessage
                (reset)="_formService.resetFormGroup(this)"
              ></app-existing-name-and-type>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'domesticRegistration'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Domestic Registration State</h2>
              <p>
                We also need to know which state you've registered as your state of domestic
                registration. You may have registered in many states, but there is only one home
                state where you're subject to the tax and legal laws of that state. That's the
                domestic registration state.
              </p>
              <!-- Telling the component "llc" to not show questions -->
              <app-domestic-registration-state
                entityType="llc"
                formControlName="domesticRegistration"
              ></app-domestic-registration-state>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'existingForeignRegistration'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Existing Foreign Registration States</h2>
              <p>
                Enter the states where you’ve already completed a foreign registration. If you have
                it, enter the date of qualification for each registration.
              </p>
              <app-foreign-registration-states
                suppressValidationMessage
                [entityType]="this.form.value['existingBusinessDetails'].entityType.code"
                [domesticState]="this.form.value['domesticRegistration'].domesticState"
                formControlName="existingForeignRegistration"
                [isExternal]="true"
              ></app-foreign-registration-states>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'newForeignRegistration'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">New Foreign Registration States</h2>
              <p>Select which state(s) you'd like to register your business in.</p>
              <app-foreign-registration-states
                suppressValidationMessage
                [showOptOutToggle]="false"
                [entityType]="this.form.value['existingBusinessDetails'].entityType.code"
                [domesticState]="this.form.value['domesticRegistration'].domesticState"
                [foreignStates]="existingForeignStates"
                formControlName="newForeignRegistration"
              ></app-foreign-registration-states>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'contactInformation'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Primary Business Contact Information</h2>
              <p>
                Who should be contacted about matters related to this business's registrations and
                matters of compliance.
              </p>
              <app-contact-information
                [entityType]="this.form.value['existingBusinessDetails'].entityType.code"
                suppressValidationMessage
                formControlName="contactInformation"
              ></app-contact-information>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'accountInformation'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Account Information</h2>
              <app-account-information
                suppressValidationMessage
                formControlName="accountInformation"
              ></app-account-information>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'additionalServices'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Additional Services</h2>
              <p>Please choose any number of additional services.</p>
              <app-recommended-services
                suppressValidationMessage
                formControlName="additionalServices"
                [hideEin]="true"
              ></app-recommended-services>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'pricingOptions'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Pricing Options</h2>
              <p>
                Integrated Stack offers 3 service packages to choose from. Pricing reflects the fees
                for the registrations you selected, your Registered Agent subscription fee and our
                service fee in one convenient price.
              </p>
            </div>
          </div>
          <app-pricing-options
            suppressValidationMessage
            [entityType]="this.form.value['existingBusinessDetails'].entityType.code"
            [foreignStates]="form.value['newForeignRegistration']"
            [contactInformation]="form.value['contactInformation']"
            [loginInformation]="form.value['accountInformation']"
            formControlName="pricingOptions"
            [showError]="showPricingError"
            (loadingInvoice)="isLoading = $event"
            [hideEinBullet]="true"
          ></app-pricing-options>
        </div>
        <div *ngSwitchCase="'reviewPayment'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Review and Payment</h2>
              <app-review-existing
                suppressValidationMessage
                formControlName="reviewPayment"
                [parentForm]="form"
                [triggerPayment]="triggerPayment"
                (handleLoading)="isLoading = $event"
                (handlePayment)="submitForm($event)"
              ></app-review-existing>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-5 md:w-8 w-full justify-content-center navigation--container">
        <div class="w-8 flex">
          <button
            pButton
            class="p-button-outlined w-6 mr-1"
            label="Previous"
            id="previousButton"
            *ngIf="currentStepIndex > 0"
            (click)="goToPreviousStep()"
          ></button>
          <button
            pButton
            label="Continue"
            id="continueButton"
            class="ml-1"
            [loading]="isLoading"
            [ngClass]="{
              'w-full': currentStepIndex === 0,
              'w-6': currentStepIndex > 0
            }"
            *ngIf="currentStepIndex !== steps.length - 1"
            (click)="goToNextStep()"
          ></button>
          <button
            pButton
            class="w-6"
            [loading]="isLoading"
            [label]="'Pay $' + payTotal"
            id="payButton"
            (click)="triggerPayment.next(true)"
            *ngIf="_formService.showPayButton(this)"
          ></button>
        </div>
      </div>
    </div>
  </div>
</app-landing-wrapper>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
