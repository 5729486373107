<div [formGroup]="form" *ngIf="!auth.currentUser; else userLoggedIn">
  <div class="mb-4">
    <p>Create an Email/Password Sign In</p>
    <span class="p-float-label">
      <input id="email" formControlName="email" type="text" pInputText />
      <label for="email">Email</label>
    </span>

    <span class="p-float-label">
      <input
        id="password"
        formControlName="password"
        type="password"
        pInputText
      />
      <label for="password">Password</label>
    </span>
  </div>
  <div class="mt-4">
    <p>Or Create a Sign In with Google</p>
    <img
      (click)="googleSigninPopup()"
      type="button"
      alt="logo"
      src="assets/images/btn_google_signin_dark_normal_web.png"
    />
  </div>
</div>
<ng-template #userLoggedIn>
  <h3>
    No information required. Your new business will be associated with the
    current account.
  </h3>
</ng-template>
<div *ngIf="googleUser" class="mt-4">
  <h4>Signed in as:</h4>
  <span>{{ googleUser.email }}</span>
</div>
