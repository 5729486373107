import { state } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-domestic-registration-state',
  templateUrl: './domestic-registration-state.component.html',
  styleUrls: ['./domestic-registration-state.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DomesticRegistrationStateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DomesticRegistrationStateComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class DomesticRegistrationStateComponent implements OnInit {
  @Input() set entityType(value: string) {
    if (value === 'corporation') {
      this.form.setControl(
        'questions',
        new FormGroup({
          0: new FormGroup({
            question: new FormControl('Par Value'),
            answer: new FormControl(null, Validators.required),
          }),
          1: new FormGroup({
            question: new FormControl('Number of Shares Authorized'),
            answer: new FormControl(null, Validators.required),
          }),
        })
      );
    }
    this.entity = value;
  }

  entity: string;

  form: FormGroup = new FormGroup({
    domesticState: new FormControl(null, Validators.required),
    questions: new FormControl(),
  });

  states = this._constants.states;

  constructor(private _constants: ConstantsService) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { domesticRegistrationState: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
