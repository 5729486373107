import { Component, forwardRef, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-arizona-corp-questions',
  templateUrl: './arizona-corp-questions.component.html',
  styleUrls: ['./arizona-corp-questions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ArizonaCorpQuestionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ArizonaCorpQuestionsComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class ArizonaCorpQuestionsComponent implements OnInit {
  form = new FormGroup({
    0: new FormGroup({
      question:
        new FormControl(`Has any person (a) who is currently an officer, director, trustee, or
      incorporator, or (b) who controls or holds over ten percent of the issued
      and outstanding common shares or ten percent of any other proprietary,
      beneficial or membership interest in the corporation been:`),
      answer: new FormControl(),
    }),
    1: new FormGroup({
      question:
        new FormControl(`Convicted of a felony involving a transaction in securities, consumer
      fraud or antitrust in any state or federal jurisdiction within the seven
      year period immediately preceding the signing of this certificate?`),
      answer: new FormControl(null, Validators.required),
    }),
    2: new FormGroup({
      question:
        new FormControl(`Convicted of a felony, the essential elements of which consisted of fraud,
      misrepresentation, theft by false pretenses or restraint of trade or
      monopoly in any state or federal jurisdiction within the 7-year period
      immediately preceding the signing of this certificate?`),
      answer: new FormControl(null, Validators.required),
    }),
    3: new FormGroup({
      question:
        new FormControl(`Subject to an injunction, judgment, decree or permanent order of any state
        or federal court entered within the seven-year period immediately preceding
        the signing of this certificate, involving any of the following: a. The violation of fraud or registration provisions of the securities
      laws of that jurisdiction; b. The violation of the consumer fraud laws of
      that jurisdiction; c. The violation of the antitrust or restraint of trade
      laws of that jurisdiction?`),
      answer: new FormControl(null, Validators.required),
    }),
    4: new FormGroup({
      question:
        new FormControl(`Has any person (a) who is currently an officer, director, trustee,
      incorporator, or (b) who controls or holds over twenty per cent of the
      issued and outstanding common shares or twenty per cent of any other
      proprietary, beneficial or membership interest in the corporation, served
      in any such capacity or held a twenty percent interest in any other
      corporation (not the one filing this Certificate) on the bankruptcy or
      receivership of the other corporation?`),
      answer: new FormControl(null, Validators.required),
    }),
  });

  selectOptions = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ];
  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
    setTimeout(() => {
      this.form.updateValueAndValidity();
    }, 0);
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { foreignRegistrationStates: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
