import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlErrorComponent } from './components/control-error/control-error.component';
import { HeaderComponent } from './components/header/header.component';
import { LandingWrapperComponent } from './components/landing-wrapper/landing-wrapper.component';
import { ControlErrorsDirective } from './directives/control-errors.directive';
import { FormSubmitDirective } from './directives/form-submit.directive';
import { SmoothHeightDirective } from './directives/smooth-height.directive';
import { PrimengModule } from '../primeng/primeng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddressInputComponent } from './components/register-stepper/components/address-input/address-input.component';
import { NumberInputComponent } from './components/register-stepper/components/number-input/number-input.component';
import { StateInputComponent } from './components/register-stepper/components/state-input/state-input.component';
import { TextInputComponent } from './components/register-stepper/components/text-input/text-input.component';
import { YesNoInputComponent } from './components/register-stepper/components/yes-no-input/yes-no-input.component';
import { AccountInformationComponent } from './components/register-stepper/forms/account-information/account-information.component';
import { BusinessNameAndTypeComponent } from './components/register-stepper/forms/business-name-and-type/business-name-and-type.component';
import { ContactInformationComponent } from './components/register-stepper/forms/contact-information/contact-information.component';
import { DomesticRegistrationStateComponent } from './components/register-stepper/forms/domestic-registration-state/domestic-registration-state.component';
import { ForeignRegistrationStatesComponent } from './components/register-stepper/forms/foreign-registration-states/foreign-registration-states.component';
import { PricingOptionsComponent } from './components/register-stepper/forms/pricing-options/pricing-options.component';
import { RecommendedServicesComponent } from './components/register-stepper/forms/recommended-services/recommended-services.component';
import { ReviewComponent } from './components/register-stepper/forms/review/review.component';
import { RegisterStepperComponent } from './components/register-stepper/register-stepper.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { AlaskaCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/alaska-corp-questions/alaska-corp-questions.component';
import { ArizonaCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/arizona-corp-questions/arizona-corp-questions.component';
import { ArkansasCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/arkansas-corp-questions/arkansas-corp-questions.component';
import { CaliforniaLlcQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/california-llc-questions/california-llc-questions.component';
import { ConnecticutCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/connecticut-corp-questions/connecticut-corp-questions.component';
import { IllinoisCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/illinois-corp-questions/illinois-corp-questions.component';
import { MaineLlcQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/maine-llc-questions/maine-llc-questions.component';
import { MassachusettsLlcQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/massachusetts-llc-questions/massachusetts-llc-questions.component';
import { NewHampshireCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/new-hampshire-corp-questions/new-hampshire-corp-questions.component';
import { NewMexicoCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/new-mexico-corp-questions/new-mexico-corp-questions.component';
import { NewYorkCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/new-york-corp-questions/new-york-corp-questions.component';
import { OhioCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/ohio-corp-questions/ohio-corp-questions.component';
import { OklahomaCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/oklahoma-corp-questions/oklahoma-corp-questions.component';
import { PennsylvaniaCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/pennsylvania-corp-questions/pennsylvania-corp-questions.component';
import { RhodeIslandCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/rhode-island-corp-questions/rhode-island-corp-questions.component';
import { WestVirginiaCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/west-virginia-corp-questions/west-virginia-corp-questions.component';
import { WisconsinCorpQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/wisconsin-corp-questions/wisconsin-corp-questions.component';
import { ConnecticutLlcQuestionsComponent } from './components/register-stepper/forms/foreign-registration-states/connecticut-llc-questions/connecticut-llc-questions.component';
import { FooterComponent } from './components/footer/footer.component';
import { TAndCCopyComponent } from './components/register-stepper/components/t-and-c-copy/t-and-c-copy.component';
import { AddRegistrationStepperComponent } from './components/add-registration-stepper/add-registration-stepper.component';
import { ExistingRegistrationStepperComponent } from './components/existing-registration-stepper/existing-registration-stepper.component';
import { ChooseAddOrExistingRegistrationComponent } from './components/choose-add-or-existing-registration/choose-add-or-existing-registration.component';
import { ExistingNameAndTypeComponent } from './components/existing-registration-stepper/components/existing-name-and-type/existing-name-and-type.component';
import { ReviewExistingComponent } from './components/existing-registration-stepper/components/review-existing/review-existing.component';

@NgModule({
  declarations: [
    ControlErrorsDirective,
    ControlErrorComponent,
    FormSubmitDirective,
    LandingWrapperComponent,
    BusinessNameAndTypeComponent,
    SmoothHeightDirective,
    HeaderComponent,
    DomesticRegistrationStateComponent,
    YesNoInputComponent,
    TextInputComponent,
    AddressInputComponent,
    NumberInputComponent,
    StateInputComponent,
    ForeignRegistrationStatesComponent,
    RecommendedServicesComponent,
    PricingOptionsComponent,
    ReviewComponent,
    ContactInformationComponent,
    AccountInformationComponent,
    RegisterStepperComponent,
    AlaskaCorpQuestionsComponent,
    ArkansasCorpQuestionsComponent,
    ArizonaCorpQuestionsComponent,
    ConnecticutCorpQuestionsComponent,
    IllinoisCorpQuestionsComponent,
    NewHampshireCorpQuestionsComponent,
    NewMexicoCorpQuestionsComponent,
    NewYorkCorpQuestionsComponent,
    OhioCorpQuestionsComponent,
    OklahomaCorpQuestionsComponent,
    PennsylvaniaCorpQuestionsComponent,
    RhodeIslandCorpQuestionsComponent,
    WestVirginiaCorpQuestionsComponent,
    WisconsinCorpQuestionsComponent,
    CaliforniaLlcQuestionsComponent,
    MassachusettsLlcQuestionsComponent,
    MaineLlcQuestionsComponent,
    ConnecticutLlcQuestionsComponent,
    FooterComponent,
    TAndCCopyComponent,
    AddRegistrationStepperComponent,
    ExistingRegistrationStepperComponent,
    ChooseAddOrExistingRegistrationComponent,
    ExistingNameAndTypeComponent,
    ReviewExistingComponent,
  ],
  imports: [
    CommonModule,
    PrimengModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey),
  ],
  exports: [
    ControlErrorsDirective,
    ControlErrorComponent,
    FormSubmitDirective,
    LandingWrapperComponent,
    BusinessNameAndTypeComponent,
    SmoothHeightDirective,
    HeaderComponent,
    DomesticRegistrationStateComponent,
    YesNoInputComponent,
    TextInputComponent,
    AddressInputComponent,
    NumberInputComponent,
    StateInputComponent,
    ForeignRegistrationStatesComponent,
    RecommendedServicesComponent,
    PricingOptionsComponent,
    ReviewComponent,
    ContactInformationComponent,
    AccountInformationComponent,
    RegisterStepperComponent,
    FooterComponent,
    AddRegistrationStepperComponent,
  ],
  entryComponents: [ControlErrorComponent],
})
export class SharedModule {}
