<div [formGroup]="form">
  <div class="mt-3">
    <h3>Rhode Island</h3>
  </div>
  <div [formGroupName]="0">
    <label for="q-0">
      {{ form.controls[0].value.question }}
    </label>
    <input
      id="q-0"
      [formControl]="form.controls[0]['controls'].answer"
      type="text"
      pInputText
    />
  </div>
  <div [formGroupName]="1">
    <label for="q-1">
      {{ form.controls[1].value.question }}
    </label>
    <input
      id="q-1"
      [formControl]="form.controls[1]['controls'].answer"
      type="number"
      pInputText
    />
  </div>
  <div [formGroupName]="2">
    <label for="q-2">
      {{ form.controls[2].value.question }}
    </label>
    <input
      id="q-2"
      [formControl]="form.controls[2]['controls'].answer"
      type="number"
      pInputText
    />
  </div>
  <div [formGroupName]="3">
    <label for="q-3">
      {{ form.controls[3].value.question }}
    </label>
    <input
      id="q-3"
      [formControl]="form.controls[3]['controls'].answer"
      type="number"
      pInputText
    />
  </div>
</div>
