import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-wrapper',
  templateUrl: './landing-wrapper.component.html',
  styleUrls: ['./landing-wrapper.component.scss'],
})
export class LandingWrapperComponent implements OnInit {
  @Input() suppressSidebar = false;
  @Input() modalExperience = false;

  constructor() {}

  ngOnInit(): void {}
}
