import { version } from '../version';

export const environment = {
  production: false,
  test: true,
  useEmulators: false,
  apiUrl: 'https://integrated-stack-api-uhoxdzwtma-uc.a.run.app',
  firebase: {
    authDomain: 'test.integratedstack.com',
    projectId: 'integrated-stack-test',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDROeM-ObAFVOwlf62nS3losX_NMTRiWjE',
    authDomain: 'integrated-stack-test.firebaseapp.com',
    projectId: 'integrated-stack-test',
    storageBucket: 'integrated-stack-test.appspot.com',
    messagingSenderId: '111265855325',
    appId: '1:111265855325:web:a495ce10e3ee46f1a4dab4',
    measurementId: 'G-SY6BWEBKS4',
  },
  stripePublishableKey:
    'pk_test_51JfF17I2QACFXZ2IyOieAMlp3li6bqS0SuTbQ5gu0cTPKOqUd2Mo8IbxHjrjWms7JVwyw141kzBrzqhw5SbgwXM4003Cev5rwu',
  version: `${version}:test`,
};
