<app-header [rightMenuConfig]="headerMenuItems" *ngIf="!isCurrentUser"></app-header>
<app-landing-wrapper [modalExperience]="false" class="z-4">
  <div sidebar class="z-4">
    <h3 class="mt-1">Newy Business Registration</h3>
    <p-progressBar [showValue]="false" [value]="progress"></p-progressBar>
    <span class="mt-1 block text-sm">{{ progress }}% Complete</span>
    <ol class="mt-5 pl-0">
      <li
        *ngFor="let step of steps; let i = index"
        class="mb-3 p-2"
        [ngClass]="{
          'active-step': currentStepIndex === i,
          'disabled-button': !touchedSteps[i] || processingPayment
        }"
        (click)="jumpToStep(i)"
      >
        {{ i + 1 }}.
        {{ step.value }}

        <i
          *ngIf="touchedSteps[i] && form.controls[i]?.valid; else stepHasError"
          class="pi pi-check ml-1"
        ></i>
        <ng-template #stepHasError>
          <i *ngIf="touchedSteps[i] && currentStepIndex !== i" class="pi pi-times"></i>
        </ng-template>
      </li>
    </ol>
  </div>
  <div content>
    <div class="mobile-progress">
      <h3 class="mt-1 text-bold">New Business Registration</h3>
      <p-progressBar [showValue]="false" [value]="progress"></p-progressBar>
      <span class="mt-1 block text-sm"
        ><span class="text-bold">Step {{ currentStepIndex + 1 }} of 8:</span> {{ progress }}%
        Complete</span
      >
    </div>
    <div [formGroup]="form">
      <div
        [ngSwitch]="steps[currentStepIndex].key"
        [smoothHeight]="currentStepIndex"
        class="steps--container"
      >
        <div *ngSwitchCase="'businessNameAndType'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Business name, entity type & industry</h2>
              <p>
                Your first choice name may not be available. Give us two additional options to try.
              </p>
              <app-business-name-and-type
                formControlName="businessNameAndType"
                suppressValidationMessage
                (reset)="_formService.resetFormGroup(this)"
              ></app-business-name-and-type>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'domesticRegistration'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Domestic Registration State</h2>
              <p>
                Domestic registration is where your company will be based. It may operate in other
                states, but all companies have only one home state where you're subject to the tax
                and legal laws of that state.
              </p>
              <app-domestic-registration-state
                suppressValidationMessage
                [entityType]="this.form.value['businessNameAndType'].entityType.code"
                formControlName="domesticRegistration"
              ></app-domestic-registration-state>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'foreignRegistrationStates'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Foreign Registration State</h2>
              <p>Select which state(s) you'd like to register your business in.</p>
              <app-foreign-registration-states
                suppressValidationMessage
                [entityType]="this.form.value['businessNameAndType'].entityType.code"
                [domesticState]="this.form.value['domesticRegistration'].domesticState"
                formControlName="foreignRegistrationStates"
              ></app-foreign-registration-states>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'businessContact'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Primary Business Contact Information</h2>
              <p>
                Who should be contacted about matters related to this business's registrations and
                matters of compliance.
              </p>
              <app-contact-information
                [entityType]="this.form.value['businessNameAndType'].entityType.code"
                suppressValidationMessage
                formControlName="businessContact"
              ></app-contact-information>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'accountInformation'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Account Information</h2>
              <app-account-information
                suppressValidationMessage
                formControlName="accountInformation"
              ></app-account-information>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'additionalServices'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Additional Services</h2>
              <p>Please choose any number of additional services.</p>
              <app-recommended-services
                suppressValidationMessage
                formControlName="additionalServices"
              ></app-recommended-services>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'pricingOptions'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Pricing Options</h2>
              <p>
                Integrated Stack offers 3 service packages to choose from. Pricing reflects the fees
                for the registrations you selected, your Registered Agent subscription fee and our
                service fee in one convenient price.
              </p>
            </div>
          </div>
          <app-pricing-options
            suppressValidationMessage
            [entityType]="form.value['businessNameAndType'].entityType.code"
            [domesticState]="form.value['domesticRegistration'].domesticState"
            [foreignStates]="form.value['foreignRegistrationStates']"
            [contactInformation]="form.value['businessContact']"
            [loginInformation]="form.value['accountInformation']"
            formControlName="pricingOptions"
            [showError]="showPricingError"
            (loadingInvoice)="isLoading = $event"
          ></app-pricing-options>
        </div>
        <div *ngSwitchCase="'reviewPayment'">
          <div class="justify-content-center flex">
            <div class="lg:w-8">
              <h2 class="mt-0">Review and Payment</h2>
              <app-review
                suppressValidationMessage
                formControlName="reviewPayment"
                [parentForm]="form"
                [triggerPayment]="triggerPayment"
                (handleLoading)="isLoading = $event"
                (handlePayment)="submitForm($event)"
              ></app-review>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-5 md:w-8 w-full justify-content-center navigation--container">
        <div class="w-8 flex">
          <button
            pButton
            class="p-button-outlined w-6 mr-1"
            label="Previous"
            id="previousButton"
            *ngIf="currentStepIndex > 0 && !processingPayment"
            (click)="goToPreviousStep()"
          ></button>
          <button
            pButton
            label="Continue"
            id="continueButton"
            class="ml-1"
            [loading]="isLoading"
            [ngClass]="{ 'w-full': currentStepIndex === 0, 'w-6': currentStepIndex > 0 }"
            *ngIf="currentStepIndex !== steps.length - 1"
            (click)="goToNextStep()"
          ></button>
          <button
            pButton
            class="w-6"
            [loading]="isLoading"
            [label]="'Pay $' + payTotal"
            id="payButton"
            (click)="triggerPayment.next(true)"
            *ngIf="_formService.showPayButton(this)"
          ></button>
        </div>
      </div>
    </div>
  </div>
</app-landing-wrapper>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
