<div [formGroup]="form">
  <div class="mt-3">
    <h3>Connecticut</h3>
  </div>
  <ng-container *ngIf="!form.controls[1].value.answer">
    <label for="q-0" class="mt-3">{{ form.controls[0].value.question }}</label>
    <div
      class="mt-3"
      *ngFor="let address of form.controls[0]['controls'].answer['controls']; let i = index"
    >
      <app-address-input
        id="q-0"
        [entityType]="entityType"
        [formControl]="address"
      ></app-address-input>
      <div class="flex mt-3 justify-content-end">
        <button
          pButton
          class="p-button-outlined mr-1"
          label="Remove Officer"
          *ngIf="i > 0"
          (click)="removeAdditionalAddress(i)"
        ></button>
        <button pButton label="Add Additional Member" (click)="addAdditionalAddress()"></button>
      </div>
    </div>
  </ng-container>
  <div class="mt-3">
    <div>Residence Address Waiver Option</div>
    <div class="mt-3 flex">
      <div class="flex align-items-center">
        <p-checkbox
          id="inLieu"
          [binary]="true"
          [formControl]="form.controls[1]['controls'].answer"
          class="mr-3"
        ></p-checkbox>
      </div>
      <label for="inLieu">{{ form.controls[1].value.question }}</label>
    </div>
  </div>
</div>
