<footer class="py-5 footer">
    <div class="footer__container grid flex flex-column md:flex-row align-items-center md:justify-content:start justify-content-center">
        <div class="footer__info flex flex-column md:mr-auto md:col-6">
            <span class="footer__copyright text-center md:text-left">&copy; {{ dateToday | date:'yyyy' }} Integrated Stack. All rights reserved.</span>
            <span class="w-full block mt-2 text-center md:text-left">version: {{version}}</span>
        </div>
        <ul class="footer__list mt-4 md:mt-2 flex md:text-left md:justify-content-start md:align-items-start">
            <li class="footer__item"><a href="https://www.integratedstack.com/privacy-policy" target="_blank" class="footer__link">Privacy Policy</a></li>
            <li class="footer__item"><a href="#" class="footer__link">Terms & Condition</a></li>
            <li class="footer__item"><a href="https://www.integratedstack.com/contact" target="_blank" class="footer__link">Contact</a></li>
        </ul>
    </div>
</footer>
