import { Component, forwardRef, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-arkansas-corp-questions',
  templateUrl: './arkansas-corp-questions.component.html',
  styleUrls: ['./arkansas-corp-questions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ArkansasCorpQuestionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ArkansasCorpQuestionsComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class ArkansasCorpQuestionsComponent implements OnInit {
  form = new FormGroup({
    0: new FormGroup({
      question:
        new FormControl(`Number of shares of the corporation's capital stock owned or
      to be owned by residents in Arkansas`),
      answer: new FormControl(),
    }),
    1: new FormGroup({
      question:
        new FormControl(`Par value of shares of the corporation's capital stock owned or
      to be owned by residents in Arkansas`),
      answer: new FormControl(),
    }),
    2: new FormGroup({
      question: new FormControl(`No Arkansas residents will own capital stock`),
      answer: new FormControl(null),
    }),
  });
  constructor() {}

  ngOnInit(): void {
    const conditionallyRequiredValidator = (formGroup) => {
      // Questions 1 and 2 are required unless the checkbox is selected
      if (!formGroup?.value[2]?.answer) {
        return formGroup?.value[0]?.answer &&
          formGroup?.value[1]?.answer &&
          Validators.required(formGroup?.value[0]) &&
          Validators.required(formGroup?.value[1])
          ? null
          : {
              conditionallyRequired: true,
            };
      }
      return null;
    };
    this.form.setValidators([conditionallyRequiredValidator]);
    this.form.updateValueAndValidity();

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
    this.form.controls[2].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.form.controls[1]['controls'].answer.reset();
          this.form.controls[0]['controls'].answer.reset();
        }
      });
    setTimeout(() => {
      this.form.updateValueAndValidity();
    }, 0);
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { foreignRegistrationStates: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
