<app-header *ngIf="!isCurrentUser" [rightMenuConfig]="headerMenuItems"></app-header>
<div class="h-full w-full flex align-items-center justify-content-center">
  <div class="mt:mt-8 mt-4 flex-column align-items-center">
    <h1 class="text-center">Register New or Existing Business</h1>
    <div class="md:my-6 my-4 flex flex-column md:flex-row justify-content-around">
      <div
        class="md:w-4 shadow-2 p-3 select-card m-3"
        [ngClass]="{ selected: selectedOption === 'new' }"
        (click)="selectedOption = 'new'"
      >
        <h3 class="mt-1 text-bold text-center">Register a New Business</h3>
        <p class="text-center">Choose this option if you have a new business to register.</p>
      </div>
      <div
        class="md:w-4 shadow-2 p-3 select-card m-3"
        (click)="selectedOption = 'existing'"
        [ngClass]="{ selected: selectedOption === 'existing' }"
      >
        <h3 class="mt-1 text-bold text-center">Set up an Existing Business</h3>
        <p class="text-center">
          Choose this option if you've already registered your business as an LLC or Corporation
        </p>
      </div>
    </div>
    <div class="flex justify-content-center">
      <button
        pButton
        label="Continue"
        id="continueButton"
        class="ml-1 w-6"
        (click)="continue()"
      ></button>
    </div>
  </div>
</div>
