import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EntityType } from '@types';

@Component({
  selector: 'app-california-llc-questions',
  templateUrl: './california-llc-questions.component.html',
  styleUrls: ['./california-llc-questions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaliforniaLlcQuestionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CaliforniaLlcQuestionsComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class CaliforniaLlcQuestionsComponent implements OnInit {
  @Input() entityType: EntityType;
  form = new FormGroup({
    0: new FormGroup({
      question: new FormControl(`Does the LLC have a Chief Executive Officer?`),
      answer: new FormControl(null, Validators.required),
    }),
    1: new FormGroup({
      question: new FormControl(`Name and Business Address of Chief Executive Officer`),
      answer: new FormControl(),
    }),
  });

  selectOptions = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ];
  constructor() {}

  ngOnInit(): void {
    const firstControl = this.form.get('0');
    firstControl?.get('answer')?.updateValueAndValidity();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
    firstControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value.answer === false) {
        const secondControl = this.form.get('1');
        const answer = secondControl?.get('answer');
        answer?.setValue(null);
        answer?.setErrors(null);
      }
    });
    // needed to manually trigger parent form's validation
    setTimeout(() => {
      this.form.updateValueAndValidity();
    }, 0);
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { foreignRegistrationStates: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
