import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { User } from '@angular/fire/auth';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { BillingService } from 'src/app/services/billing.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TriggerValidationService } from 'src/app/services/trigger-validation.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReviewComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReviewComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class ReviewComponent implements OnInit {
  @Input() entityType: string;
  @Input() domesticState: number;
  @Input() disabledServices: number[];
  @Input() hideEin = false;
  @Input() showOptOutToggle = true;

  @ViewChild(StripeCardComponent) card: StripeCardComponent | undefined = undefined;

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        fontWeight: 500,
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '18px',
      },
    },
  };
  form = new FormGroup({
    termsAndConditions: new FormControl(false, Validators.requiredTrue),
    registeredAgent: new FormControl(false, Validators.requiredTrue),
    signingAgreement: new FormControl(false, Validators.requiredTrue),
  });
  parentFormGroup = new FormGroup({});
  termsAndConditionsDisplay = false;
  signingAgreementDisplay = false;
  registeredAgentDisplay = false;
  @Input() set parentForm(form: FormGroup) {
    Object.keys(form.controls).forEach((controlKey) => {
      this.parentFormGroup.addControl(controlKey, new FormControl(null));
    });

    this.selectedPlan = form.value['pricingOptions']?.optionSelected;
    this.selectedInvoice = form.value['pricingOptions']?.selectedInvoice;
    this.coupon = form.value['pricingOptions']?.coupon;
    this.discount = form.value['pricingOptions']?.discount;
    this.totalCost = form.value['pricingOptions']?.totalCost;
    this.credentials = form.value['accountInformation'];
    this.parentFormGroup.patchValue(form.value);
    Object.keys(this.parentFormGroup.controls).forEach((controlKey) =>
      this.parentFormGroup.get(controlKey)?.disable(),
    );
  }
  @Input() triggerPayment: Subject<boolean> | undefined;

  @Output() handleLoading = new EventEmitter();
  @Output() handlePayment = new EventEmitter();
  selectedPlan: any;
  selectedInvoice: any;
  coupon: any;
  totalCost: any;
  discount: any;
  credentials: {
    email?: string;
    password?: string;
    googleUser?: User;
  };
  constructor(
    private _stripeService: StripeService,
    private _triggerValidation: TriggerValidationService,
    private _notificationService: NotificationService,
    private _billingService: BillingService,
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });

    this.triggerPayment?.pipe(untilDestroyed(this)).subscribe(() => {
      this.submitPayment();
    });
  }

  async submitPayment() {
    if (this.form.invalid) {
      this._triggerValidation.triggerValidation();
      this.handleLoading.emit(false);
      return;
    }

    this.handleLoading.emit(true);
    if (this.coupon) {
      await this._billingService
        .addDiscountToInvoice({ invoiceId: this.selectedInvoice.id, couponId: this.coupon.id })
        .pipe(take(1))
        .toPromise();
    }
    this.card &&
      this._stripeService
        .createPaymentMethod({
          type: 'card',
          card: this.card.element,
        })
        .subscribe((result) => {
          if (result.paymentMethod) {
            this.handlePayment.emit(result.paymentMethod.id);
          }
          if (result.error) {
            this.handleLoading.emit(false);
            this._notificationService.showErrorNotification(`${result.error.message}`);
          }
        });
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { businessNameAndType: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
