<header class="py-3 px-6 header--container">
  <h1 class="my-0">Integrated Stack</h1>
  <ul
    class="p-0 m-0 align-items-center lg:flex-row header--right-nav"
  >
    <li class="ml-3" *ngFor="let menuItem of rightMenuConfig">

      <button
        pButton
        [classList]="[menuItem.styleClass]"
        [routerLink]="menuItem.routerLink"
        [label]="menuItem.title || ''"
      ></button>
    </li>
  </ul>
</header>
