import { Component, forwardRef, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Auth,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  User,
} from '@angular/fire/auth';
import { doesEmailExist } from 'src/app/shared/validators';
import { AuthService } from 'src/app/services/auth.service';

const googleProvider = new GoogleAuthProvider();

@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountInformationComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AccountInformationComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class AccountInformationComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl(
      '',
      [Validators.required, Validators.email],
      doesEmailExist(this._authService)
    ),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    googleUser: new FormControl(null),
  });
  signedIn = false;
  constructor(public auth: Auth, private _authService: AuthService) {}

  get googleUser() {
    return this.form.get('googleUser')?.value;
  }
  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
    this.auth.onAuthStateChanged((auth) => {
      if (auth) {
        // check to see if user is from google auth
        if (
          auth?.providerData.find(
            (provider) => provider.providerId === 'google.com'
          )
        ) {
          this.form.get('googleUser')?.setValue(auth);
          this.signedIn = true;
        }
        // check to see if user signed in with password
        if (
          auth?.providerData.find(
            (provider) => provider.providerId === 'password'
          )
        ) {
          this.signedIn = true;
          this.form.get('email')?.clearAsyncValidators();
          this.form.get('email')?.setValue(auth.email);
          this.form.get('password')?.clearValidators();
          this.form.get('password')?.setErrors(null);
          this.form.updateValueAndValidity();
        }
      }
    });
    this.form
      .get('googleUser')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((googleUser) => {
        if (googleUser) {
          this.form.get('email')?.disable();
          this.form.get('email')?.removeValidators(Validators.required);
          this.form.get('password')?.disable();
          this.form.get('password')?.removeValidators(Validators.required);
        } else {
          if (!this.signedIn) {
            this.form.get('email')?.addValidators(Validators.required);
            this.form.get('email')?.enable();
            this.form.get('password')?.addValidators(Validators.required);
            this.form.get('password')?.enable();
          }
        }
      });
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { accountInformation: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  googleSigninPopup() {
    signInWithPopup(this.auth, googleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log(user);

        console.log(credential?.toJSON());
        this.form.get('googleUser')?.setValue(user);
        return { user, credential };
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        return { user: null, credential };
        // ...
      });
  }
}
