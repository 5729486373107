import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() rightMenuConfig: MenuItem[] = [];
  @Input() leftMenuConfig: MenuItem[] = [];

  constructor() {}

  ngOnInit(): void {}
}
