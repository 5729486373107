<div [formGroup]="form">
  <div class="mt-3">
    <h3>Oklahoma</h3>
  </div>
  <div [formGroupName]="0" class="mt-3">
    <label for="q-0">
      {{ form.controls[0].value.question }}
    </label>
    <input
      id="q-0"
      [formControl]="form.controls[0]['controls'].answer"
      type="number"
      pInputText
    />
  </div>
</div>
