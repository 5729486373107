import { Component, forwardRef, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-illinois-corp-questions',
  templateUrl: './illinois-corp-questions.component.html',
  styleUrls: ['./illinois-corp-questions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IllinoisCorpQuestionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IllinoisCorpQuestionsComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class IllinoisCorpQuestionsComponent implements OnInit {
  form = new FormGroup({
    0: new FormGroup({
      question: new FormControl(
        'States and countries in which the corporation is qualified to transact business'
      ),
      answer: new FormControl('', Validators.required),
    }),
    1: new FormGroup({
      question: new FormControl('Total Paid-In Capital'),
      answer: new FormControl(null, Validators.required),
    }),
    2: new FormGroup({
      question: new FormControl(
        'Estimated total value of all property of corporation for following year'
      ),
      answer: new FormControl(null, Validators.required),
    }),
    3: new FormGroup({
      question: new FormControl(
        'Estimated total value of all property of corporation for following year located in IL'
      ),
      answer: new FormControl(null, Validators.required),
    }),
    4: new FormGroup({
      question: new FormControl(
        'Estimated total business of corporation to be transacted by it everywhere for the following year'
      ),
      answer: new FormControl(null, Validators.required),
    }),
    5: new FormGroup({
      question: new FormControl(
        'Estimated annual business of corporation to be transacted by it at or from places of business in IL'
      ),
      answer: new FormControl(null, Validators.required),
    }),
  });
  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
    setTimeout(() => {
      this.form.updateValueAndValidity();
    }, 0);
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { foreignRegistrationStates: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
