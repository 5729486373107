import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { DeactivateGuard } from './guards/deactivate.guard';
import { ChooseAddOrExistingRegistrationComponent } from './shared/components/choose-add-or-existing-registration/choose-add-or-existing-registration.component';
import { ExistingRegistrationStepperComponent } from './shared/components/existing-registration-stepper/existing-registration-stepper.component';
import { RegisterStepperComponent } from './shared/components/register-stepper/register-stepper.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: ChooseAddOrExistingRegistrationComponent,
    canDeactivate: [DeactivateGuard],
    data: {
      routeOutlet: true,
    },
  },
  {
    path: 'register/new',
    component: RegisterStepperComponent,
    canDeactivate: [DeactivateGuard],
    data: {
      routeOutlet: true,
    },
  },
  {
    path: 'register/existing',
    component: ExistingRegistrationStepperComponent,
  },
  {
    path: 'create-account',
    component: RegisterComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
