import { Component, OnInit } from '@angular/core';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  headerMenuItems: MenuItem[] = [
    {
      title: 'Sign In',
      routerLink: ['/login'],
      styleClass: 'p-button-text',
    },
    {
      title: 'Create Account',
      routerLink: ['/register'],
      styleClass: 'p-button-outlined dense',
    },
  ];

  constructor(
    private _auth: Auth,
    private _notificationService: NotificationService,
    private _router: Router,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.analytics.logEvent('forgot_password_view', {
      event_name: 'forgot_password_view',
      event_category: 'forgot_password',
      event_label: 'Forgot Password View',

    });
  }

  async resetPassword() {
    try {
      await sendPasswordResetEmail(
        this._auth,
        this.forgotPasswordForm.value.email
      );
      this._notificationService.showSuccessNotification(
        'Successfully requested password reset, please check your email.'
      );
      this._router.navigate(['/login']);
    } catch (error) {
      this._notificationService.showErrorNotification(
        'Unable to reset password, please check the entered email.'
      );
    }
  }
}
