<div class="grid flex mb-3">
  <div class="col-6">
    <ng-container *ngIf="servicesObservable | async as services">
      <p-checkbox
        *ngFor="let service of services"
        [(ngModel)]="selectedValues"
        (onChange)="addToForm(service.id)"
        [label]="service.label || service.name"
        [value]="service.id"
        [disabled]="disableForm || disabledObject[service.id]"
        [ngClass]="{'opacity-50' : disableForm && disabledObject[service.id]}"
      ></p-checkbox>
    </ng-container>
  </div>
</div>
