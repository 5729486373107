import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { DeactivateGuard } from 'src/app/guards/deactivate.guard';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessEntityRegisterService } from 'src/app/services/business-entity-register.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TriggerValidationService } from 'src/app/services/trigger-validation.service';
import { growAnimation } from 'src/app/utils/animations';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { FormService } from 'src/app/services/form.service';
@Component({
  selector: 'app-register-stepper',
  templateUrl: './register-stepper.component.html',
  styleUrls: ['./register-stepper.component.scss'],
  animations: [growAnimation],
  encapsulation: ViewEncapsulation.None,
})
@UntilDestroy()
export class RegisterStepperComponent implements OnInit, OnDestroy {
  isLoading = false;
  modalExperience = false;

  // Current step we are on
  currentStepIndex: number = 0;
  // Progress percentage
  progress: number = 0;
  contactInfo: any = {};
  isCurrentUser: boolean = false;
  // Represent each step in the stepper. This string is used for the step title
  steps = [
    { key: 'businessNameAndType', value: 'Business Name, Entity Type & Industry' },
    { key: 'domesticRegistration', value: 'Domestic Registration State' },
    { key: 'foreignRegistrationStates', value: 'Foreign Registration States' },
    { key: 'businessContact', value: 'Primary Business Contact Information' },
    { key: 'accountInformation', value: 'Account Information' },
    { key: 'additionalServices', value: 'Additional Services' },
    { key: 'pricingOptions', value: 'Pricing Options' },
    { key: 'reviewPayment', value: 'Review & Payment' },
  ];

  // We want to keep track of which steps have been interacted with
  // since we don't want to provide feedback on something that hasn't been
  // reached yet.
  touchedSteps: { [key: number]: boolean } = {
    0: true,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  };

  // Form groups for each step
  // Keys are number to represent their position in the stepper
  form: FormGroup = new FormGroup({
    businessNameAndType: new FormControl(null, Validators.required),
    domesticRegistration: new FormControl(null, Validators.required),
    foreignRegistrationStates: new FormControl(null, Validators.required),
    businessContact: new FormControl(null, Validators.required),
    accountInformation: new FormControl(null, Validators.required),
    additionalServices: new FormControl(null),
    pricingOptions: new FormControl(null, Validators.required),
    reviewPayment: new FormControl(null, Validators.required),
  });

  // Config for our header navigation
  headerMenuItems: MenuItem[] = [
    {
      title: 'Sign In',
      routerLink: ['/login'],
      styleClass: 'p-button-outlined',
    },
    {
      title: 'Create Account',
      routerLink: ['/create-account'],
      styleClass: 'p-button-outlined dense',
    },
  ];

  triggerPayment = new Subject<boolean>();
  ref: DynamicDialogRef;
  config: DynamicDialogConfig;
  showPricingError = false;
  processingPayment = false;
  payTotal = 0;

  beforeUnloadListener = (e: BeforeUnloadEvent) => {
    if (this.form.invalid && this.form.touched) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  constructor(
    private _triggerValidation: TriggerValidationService,
    private _businessEntityRegisterService: BusinessEntityRegisterService,
    private _auth: AuthService,
    private _fbAuth: Auth,
    private _notificationService: NotificationService,
    private _router: Router,
    private deactGuard: DeactivateGuard,
    private _constants: ConstantsService,
    private analytics: AnalyticsService,
    public _formService: FormService
  ) {
    this.isLoading = false;
    this._constants.getStates().subscribe();
    this._constants.getTitles().subscribe();
    this._constants.getIndustries().subscribe();
    this._constants.getServices().subscribe();
    this.isCurrentUser = !!this._fbAuth.currentUser;
  }

  ngOnInit() {
    this.contactInfo = history.state;
    if (this.contactInfo?.primaryContact) {
      const contactInfo = {
        ...this.contactInfo.primaryContact,
        state: this.contactInfo.primaryContact.stateId,
        dateOfBirth: this._auth.currentUser.dateOfBirth
          ? new Date(this._auth.currentUser.dateOfBirth)
          : '',
      };
      this.form.get('businessContact')?.setValue(contactInfo);
    }

    if (this._auth.currentUser) {
      this.form.get('accountInformation')?.removeValidators(Validators.required);
    }

    window.addEventListener('beforeunload', this.beforeUnloadListener);

    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe((status) => {
      if (status === 'INVALID' && this.form.touched) {
        this.deactGuard.canDeact = false;
      }

      if (status === 'VALID') {
        this.deactGuard.canDeact = true;
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  }

  // Jump to a specific step
  jumpToStep(step: number) {
    // Disable if waiting for payment processing
    if (this.processingPayment) return;

    if (this.touchedSteps[step]) {
      this.isLoading = false;
      this.currentStepIndex = step;
    }
    this.analytics.logEvent('custom_event', {
      event_category: 'register_stepper',
      event_action: 'jump_to_step',
      event_label: `${step}`,
    });
  }

  goToPreviousStep() {
    if (this.currentStepIndex !== 0) {
      this.isLoading = false;
      this.currentStepIndex = this.currentStepIndex - 1;
    }
    this.analytics.logEvent('custom_event', {
      event_category: 'register_stepper',
      event_action: 'go_to_previous_step',
      event_label: `${this.currentStepIndex}`,
    });
  }

  goToNextStep() {
    if (this.form.controls[this.steps[this.currentStepIndex].key].invalid) {
      this._triggerValidation.triggerValidation();
      if (this.currentStepIndex === 6) {
        this.showPricingError = true;
      }
      return;
    }
    if (this.currentStepIndex !== this.steps.length) {
      this.currentStepIndex++;

      // Mark the current step as touched
      this.touchedSteps[this.currentStepIndex] = true;

      // Calculate current progress
      const progress = this.calculateProgress();

      // If our progress as increased as a result, let's update the variable
      if (this.progress <= progress) {
        this.progress = this.calculateProgress();
      }
    }
    this.analytics.logEvent('custom_event', {
      event_category: 'register_stepper',
      event_action: 'next_step',
      event_label: `${this.currentStepIndex}`,
    });
  }

  /**
   * Submit Form
   *
   * Submitting the form happens after we trigger the payment by calling
   * this.triggerPayment.next(true)
   * Triggering the payment starts the review component submitting the credit card
   * information to stripe and returning an ID for the payment.
   * Once we have the ID we can create the business entity and account.  Payment ID is
   * passed into the API call as well, where we charge the CC as part of the process.
   * If the CC payment fails, the API rolls back the creation of the entity and account.
   *
   * @param paymentId
   * @returns
   */
  submitForm(paymentId: string) {
    if (this.form.controls[this.steps[this.currentStepIndex].key].invalid) {
      this._triggerValidation.triggerValidation();
      return;
    }
    const formValues = this.form.value;
    this.deactGuard.canDeact = true;

    const registration: any = {
      businessEntity: {
        businessNameChoice1: formValues['businessNameAndType'].nameOption1,
        businessNameChoice2: formValues['businessNameAndType'].nameOption2,
        businessNameChoice3: formValues['businessNameAndType'].nameOption3,
        entityType: formValues['businessNameAndType'].entityType.code,
        industry: formValues['businessNameAndType'].industry,
        services: formValues['additionalServices']?.map((service) => parseInt(service)) || [],
      },
      user: {
        signInEmail:
          formValues['accountInformation'].email ||
          formValues['accountInformation'].googleUser?.email,
        password: formValues['accountInformation'].password,
      },
      contactInfo: {
        title: formValues['businessContact'].title,
        firstName: formValues['businessContact'].firstName,
        lastName: formValues['businessContact'].lastName,
        streetAddress1: formValues['businessContact'].streetAddress1,
        streetAddress2: formValues['businessContact'].streetAddress2,
        city: formValues['businessContact'].city,
        state: formValues['businessContact'].state,
        postalCode: formValues['businessContact'].postalCode,
        phone: formValues['businessContact'].phone,
        dateOfBirth: formValues['businessContact'].dateOfBirth,
        ssn: formValues['businessContact'].ssn,
        email: formValues['businessContact'].email,
      },
      stateRegistrations: [
        {
          isDomestic: true,
          stateId: formValues['domesticRegistration'].domesticState,
          payrollRegistrationStatus: formValues['additionalServices']?.includes('3')
            ? 'pending'
            : 'not_applicable',
          salesTaxRegistrationStatus: formValues['additionalServices']?.includes('5')
            ? 'pending'
            : 'not_applicable',
          registrationQuestionResponses: formValues['domesticRegistration'].questions
            ? Object.values(formValues['domesticRegistration'].questions)
            : [],
        },
        ...formValues['foreignRegistrationStates'].states.map((stateObj) => {
          return {
            isDomestic: false,
            stateId: parseInt(stateObj.state.id),
            payrollRegistrationStatus: formValues['additionalServices']?.includes('3')
              ? 'pending'
              : 'not_applicable',
            salesTaxRegistrationStatus: formValues['additionalServices']?.includes('5')
              ? 'pending'
              : 'not_applicable',
            registrationQuestionResponses: stateObj.questions
              ? Object.values((stateObj as any).questions)
              : [],
          };
        }),
      ],
      payment: {
        paymentId,
        invoiceId: this.form.value['pricingOptions']?.selectedInvoice.id,
      },
    };

    if (this.form.value['businessNameAndType'].entityType.code === 'corporation') {
      registration.contactInfo.isDirector = formValues['businessContact'].isDirector;
    }

    this.analytics.logEvent('custom_event', {
      event_category: 'register_stepper',
      event_action: 'submit_form',
      event_label: `Submit Form`,
    });
    this.processingPayment = true;
    this.isLoading = true;
    this._businessEntityRegisterService.registerBusinessEntityAndAccount(registration).subscribe({
      next: async (businessEntity) => {
        // either a google user or existing email so we didn't enter password
        if (
          !this.form.value['accountInformation'].googleUser &&
          this.form.value['accountInformation'].password
        ) {
          await signInWithEmailAndPassword(
            this._fbAuth,
            this.form.value['accountInformation'].email as string,
            this.form.value['accountInformation'].password as string,
          );
        }
        this._notificationService.showSuccessNotification(
          'Successfully created your account and business!',
        );
        if (this.ref) {
          this.ref.close();
        } else {
          this._router.navigate(['/dashboard']);
        }
        this.analytics.logEvent('custom_event', {
          event_category: 'register_stepper',
          event_action: 'submit_form_success',
          event_label: `Register Successful`,
        });
        this.isLoading = false;
        this.processingPayment = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.processingPayment = false;
        this.deactGuard.canDeact = false;
      },
    });
  }

  // Calculate the progress
  calculateProgress(): number {
    return Math.round((this.currentStepIndex / this.steps.length) * 100);
  }

  handlePay() {
    this.triggerPayment.next();
  }
}
