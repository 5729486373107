import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private _confirmationService: ConfirmationService) {}
  @Output() reset = new EventEmitter<string>();

  onEntityTypeChange(oldValue, nextFormName, eventName, scope): void {
    if (
      scope.form.controls.entityType.value &&
      !scope.rootFormGroup.control.controls[nextFormName].pristine
    ) {
      this._confirmationService.confirm({
        message: 'Are you sure that you want to change this field? You progress will be lost.',
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => scope[eventName].emit(),
        reject: () => {
          scope.form.setValue(Object.assign({}, scope.form.value, { entityType: oldValue }));
        },
      });
    }
  }

  resetFormGroup(scope) {
    let firstStep = true;
    for (const step in scope.touchedSteps) {
      if (!firstStep) {
        scope.touchedSteps[step] = false;
      }
      firstStep = false;
    }
    let saveFirstForm = {};
    saveFirstForm[scope.steps[0].key] = scope.form.controls[scope.steps[0].key];
    scope.form.reset(saveFirstForm);
  }

  showPayButton(scope) {
    const isLastStep = scope.currentStepIndex === scope.steps.length - 1;
    if (isLastStep) {
      scope.payTotal =
        (scope.form.value['pricingOptions']?.totalCost ||
        scope.form.value['pricingOptions']?.totalCost === 0
          ? scope.form.value['pricingOptions']?.totalCost
          : scope.form.value['pricingOptions']?.selectedInvoice.total +
            Number(
              scope.form.value['pricingOptions']?.selectedInvoice.metadata.registeredAgentFee,
            )) / 100;
    }
    return isLastStep;
  }
}
