import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {
  StripeElementsOptions,
  StripeCardElementOptions,
} from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Subject } from 'rxjs';
import { User } from '@angular/fire/auth';
import { NotificationService } from 'src/app/services/notification.service';
import { TriggerValidationService } from 'src/app/services/trigger-validation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-review-existing',
  templateUrl: './review-existing.component.html',
  styleUrls: ['./review-existing.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReviewExistingComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReviewExistingComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class ReviewExistingComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent | undefined =
    undefined;

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        fontWeight: 500,
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '18px',
      },
    },
  };
  form = new FormGroup({
    termsAndConditions: new FormControl(false, Validators.requiredTrue),
    registeredAgent: new FormControl(false, Validators.requiredTrue),
    signingAgreement: new FormControl(false, Validators.requiredTrue),
  });
  parentFormGroup = new FormGroup({
    existingBusinessDetails: new FormControl(null),
    domesticRegistration: new FormControl(null),
    existingForeignRegistration: new FormControl(null),
    newForeignRegistration: new FormControl(null),
    contactInformation: new FormControl(null),
    accountInformation: new FormControl(null),
    additionalServices: new FormControl(null),
    pricingOptions: new FormControl(null),
    reviewPayment: new FormControl(null),
  });
  termsAndConditionsDisplay = false;
  signingAgreementDisplay = false;
  registeredAgentDisplay = false;

  @Input() triggerPayment: Subject<boolean> | undefined;
  @Input() set parentForm(form: FormGroup) {
    this.selectedPlan = form.value['pricingOptions']?.optionSelected;
    this.selectedInvoice = form.value['pricingOptions']?.selectedInvoice;
    this.credentials = form.value['accountInformation'];
    this.parentFormGroup.patchValue(form.value);
    Object.keys(this.parentFormGroup.controls).forEach((controlKey) =>
      this.parentFormGroup.get(controlKey)?.disable()
    );
  }
  @Output() handleLoading = new EventEmitter();
  @Output() handlePayment = new EventEmitter();
  selectedPlan: any;
  selectedInvoice: any;
  credentials: {
    email?: string;
    password?: string;
    googleUser?: User;
  };
  constructor(
    private _stripeService: StripeService,
    private _triggerValidation: TriggerValidationService,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });

    this.triggerPayment?.pipe(untilDestroyed(this)).subscribe(() => {
      this.submitPayment();
    });
  }

  submitPayment() {
    if (this.form.invalid) {
      this._triggerValidation.triggerValidation();
      this.handleLoading.emit(false);
      return;
    }

    this.handleLoading.emit(true);
    this.card &&
      this._stripeService
        .createPaymentMethod({
          type: 'card',
          card: this.card.element,
        })
        .subscribe((result) => {
          if (result.paymentMethod) {
            this.handlePayment.emit(result.paymentMethod.id);
          }
          if (result.error) {
            this.handleLoading.emit(false);
            this._notificationService.showErrorNotification(
              `${result.error.message}`
            );
          }
        });
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { businessNameAndType: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
