<app-header [rightMenuConfig]="headerMenuItems"></app-header>
<app-landing-wrapper [suppressSidebar]="true">
  <div content>
    <h1>Create a new account</h1>
    <div class="mb-3">
      <span class="text-600 font-medium mr-2">Already have an account?</span>
      <a
        class="font-medium no-underline text-blue-500 cursor-pointer"
        data-qa="register-link"
        routerLink="/login"
        >Sign in.</a
      >
    </div>
    <form [formGroup]="registerForm" (ngSubmit)="register()" content>
      <label for="firstName">First Name</label>
      <input
        id="firstName"
        formControlName="firstName"
        type="name"
        pInputText
      />

      <label for="lastName">Last Name</label>
      <input id="lastName" formControlName="lastName" type="name" pInputText />

      <label for="email">Email</label>
      <input id="email" formControlName="email" type="text" pInputText />

      <label for="password">Password</label>
      <input
        id="password"
        formControlName="password"
        type="password"
        pInputText
      />

      <button
        pButton
        [disabled]="registerForm.invalid"
        [loading]="loading"
        type="submit"
        label="Register"
      ></button>
    </form>
  </div>
</app-landing-wrapper>
<app-footer></app-footer>