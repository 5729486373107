import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-recommended-services',
  templateUrl: './recommended-services.component.html',
  styleUrls: ['./recommended-services.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecommendedServicesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecommendedServicesComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class RecommendedServicesComponent implements OnInit {
  @Input() set disabledServices(value: number[]) {
    this.disabledObject =
      value?.reduce((acc, el) => {
        acc[el] = true;
        return acc;
      }, {}) || {};
    this.selectedValues = value || [];
  }
  @Input() disableForm = false;
  @Input() hideEin = false;
  disabledObject: { [key: string]: boolean } = {};
  selectedValues: number[] = [];
  form = new FormControl([]);
  servicesObservable = this._constantsService.services.pipe(
    map((services) =>
      this.hideEin ? services.filter((service) => service.name !== 'ein') : services,
    ),
  );
  disabledArray: number[];

  constructor(private _constantsService: ConstantsService) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
  }

  addToForm(serviceId: number | number[]) {
    const { value } = this.form;
    const valueArray = value || [];
    const index = valueArray.indexOf(serviceId);
    if (index > -1) {
      valueArray.splice(index, 1);
    } else {
      valueArray.push(serviceId);
    }
    this.form.setValue([...valueArray]);
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
    this.selectedValues = value?.length ? [...this.selectedValues, ...value] : this.selectedValues;
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { recommendedServices: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
