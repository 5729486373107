<div [formGroup]="form">
  <span class="p-float-label">
    <input
      id="nameOption1"
      formControlName="nameOption1"
      type="text"
      pInputText
      data-qa="nameOption1-input"
    />
    <label for="nameOption1">Business Name - First Choice</label>
  </span>

  <span class="p-float-label">
    <input
      id="nameOption2"
      formControlName="nameOption2"
      type="text"
      pInputText
      data-qa="nameOption2-input"
    />
    <label for="nameOption2">Business Name - Second Choice</label>
  </span>

  <span class="p-float-label">
    <input
      id="nameOption3"
      formControlName="nameOption3"
      type="text"
      pInputText
      data-qa="nameOption3-input"
    />
    <label for="nameOption3">Business Name - Third Choice</label>
  </span>

  <span class="p-float-label">
    <p-dropdown
      id="entityType"
      [options]="entityTypes"
      [autoDisplayFirst]="false"
      formControlName="entityType"
      optionLabel="name"
      (onChange)="
        _formService.onEntityTypeChange(entityTypeVal, 'domesticRegistration', 'reset', this)
      "
      (onFocus)="entityTypeVal = form.controls.entityType.value"
    ></p-dropdown>
    <label for="entityType">Entity Type</label>
  </span>

  <ng-container *ngIf="industries | async as industryOptions">
    <span class="p-float-label">
      <p-dropdown
        id="industry"
        [options]="industryOptions"
        formControlName="industry"
        [autoDisplayFirst]="false"
        [filter]="true"
        filterBy="name"
        optionLabel="name"
        optionValue="id"
        [virtualScroll]="true"
        [itemSize]="30"
      >
        <ng-template let-industry pTemplate="item">
          <div class="w-20rem lg:w-28rem xl:w-30rem">
            <div class="white-space-normal">{{ industry.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <label for="industry">Industry</label>
    </span>
  </ng-container>
</div>
