<div class="flex landing">
  <div
    class="hidden lg:block w-8 sidebar--container z-4 content--container"
    *ngIf="!suppressSidebar"
  >
    <div class="sidebar--content">
      <ng-content select="[sidebar]"></ng-content>
    </div>
    <div class="bg-blue"></div>
  </div>
  <div class="surface-section w-full sm:w-12 xs:w-12 p-6 lg:p-6 align-content-center">
    <div
      [ngClass]="{ 'md:w-12': !suppressSidebar, 'md:w-6': suppressSidebar }"
      class="m-auto content--container"
    >
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>
