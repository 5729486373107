import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { capitalize } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  bullets = {
    basic: [
      'State Incorporation Services',
      'EIN Application',
      'Registered Agent Services included for the first year',
      'Access to vetted service providers',
    ],
    complete: [
      'State Incorporation Services',
      'EIN Application',
      'Registered Agent Services included for the first year',
      'Access to vetted service providers',
      'PLUS rush service as quickly as 1 business day',
    ],
    whiteglove: [
      'State Incorporation Services',
      'EIN Application (including for international individuals with no US Social Security number)',
      'Registered Agent Services included for the first year',
      'Access to vetted service providers',
      'Rush service as quickly as 1 business day',
      'PLUS a 30-minute conversation with a service agent to help guide you on your selection and submission',
    ],
  };

  descriptions = {
    basic: 'Just the basics to get started.',
    complete: 'Get started quickly with our rush service.',
    whiteglove: 'Everything you need plus a helping hand.',
  };

  constructor(private _httpClient: HttpClient, private _notificationService: NotificationService) {}

  makePayment({
    paymentMethod,
    invoice,
    businessEntityId,
  }: {
    paymentMethod: string;
    invoice: string;
    businessEntityId: number;
  }) {
    return this._httpClient
      .post(`${environment.apiUrl}/billing/make-payment`, {
        paymentMethod,
        invoice,
        businessEntityId,
      })
      .pipe(
        catchError((response: any) => {
          this._notificationService.showErrorNotification(
            'An error occured while trying to register.',
          );
          return throwError(response);
        }),
        tap(() => {
          this._notificationService.showSuccessNotification('Payment Successful!');
        }),
      );
  }

  getPriceOptions(
    data: {
      name: string;
      email: string;
      domesticState: string;
      foreignStates: string[];
      entityType: string;
    },
    hideEinBullet: boolean,
  ) {
    return this._httpClient
      .post<any>(`${environment.apiUrl}/billing/get-pricing-options`, data)
      .pipe(
        map((data) => {
          return Object.entries<any>(data).map(([key, value]) => {
            let bullets = this.bullets[key];
            if (hideEinBullet) {
              bullets = bullets.filter((bullet: string) => !bullet.toLowerCase().includes('ein'));
            }
            return {
              label: capitalize(key),
              bullets,
              description: this.descriptions[key],
              price: value.total,
              prices: value.prices.map((price) => {
                return {
                  ...price,
                  description: this.formatLookupKey(price.lookup_key),
                };
              }),
              registeredAgentFee: value.registeredAgentFee,
            };
          });
        }),
      );
  }

  getDiscount(invoiceId: string, code: string) {
    return this._httpClient.get<any>(`${environment.apiUrl}/billing/get-discount`, {
      params: new HttpParams().set('code', code).set('invoiceId', invoiceId),
    });
  }

  getInvoice(data: {
    name: string;
    email: string;
    prices: any[];
    label: string;
    promoCode: string;
    invoiceId: string;
  }) {
    return this._httpClient.post<any>(`${environment.apiUrl}/billing/get-invoice`, data);
  }

  addDiscountToInvoice(data: { invoiceId: string; couponId: string }) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/billing/add-discount-to-invoice`,
      data,
    );
  }

  formatLookupKey(lookupKey: string) {
    const split = lookupKey.split('_');
    const words = split.map((word) => {
      if (word.toLowerCase().match(/foreign|domestic|basic|complete|whiteglove/)) {
        return capitalize(word);
      } else {
        return word;
      }
    });
    return words.join(' ');
  }
}
