import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { Auth } from '@angular/fire/auth';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private _auth: Auth) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return from(
      this._auth.currentUser
        ? this._auth.currentUser.getIdToken()
        : Promise.resolve(null)
    ).pipe(
      take(1),
      switchMap((idToken) => {
        let req: HttpRequest<unknown> | null = null;

        if (idToken) {
          req = request.clone({
            setHeaders: {
              Authorization: idToken,
            },
          });
        }

        return next.handle(req || request);
      })
    );
  }
}
