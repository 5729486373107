<div [formGroup]="form">
  <div class="mt-3">
    <h3>California</h3>
  </div>
  <div [formGroupName]="0">
    <div class="mb-2">
      <label for="q-0">
        {{ form.controls[0].value.question }}
      </label>
    </div>
    <p-selectButton
      id="q-0"
      [options]="selectOptions"
      optionLabel="name"
      optionValue="value"
      [formControl]="form.controls[0]['controls'].answer"
    ></p-selectButton>
  </div>
  <ng-container *ngIf="form.controls[0].value.answer">
    <div [formGroupName]="1" class="mt-3">
      <label for="q-1">
        {{ form.controls[1].value.question }}
      </label>
      <app-address-input
        id="q-1"
        [entityType]="entityType"
        [formControl]="form.controls[1]['controls'].answer"
      ></app-address-input>
    </div>
  </ng-container>
</div>
