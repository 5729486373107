import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EntityType } from '@types';
import { map } from 'rxjs/operators';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class AddressInputComponent implements OnInit {
  @Input() entityType: EntityType;
  form = new FormGroup({
    title: new FormControl(null, Validators.required),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    streetAddress1: new FormControl('', Validators.required),
    streetAddress2: new FormControl(''),
    state: new FormControl(null, Validators.required),
    city: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
  });

  states = this._constants.states;
  titles = this._constants.titles.pipe(
    map((titles) =>
      titles.filter((title) => title.entityType === this.entityType)
    )
  );
  constructor(private _constants: ConstantsService) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
    setTimeout(() => {
      this.form.updateValueAndValidity();
    }, 0);
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { contactInformation: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
