import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { State } from '@types';
import { filter } from 'rxjs/operators';
import { ConstantsService } from 'src/app/services/constants.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-foreign-registration-states',
  templateUrl: './foreign-registration-states.component.html',
  styleUrls: ['./foreign-registration-states.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ForeignRegistrationStatesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ForeignRegistrationStatesComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class ForeignRegistrationStatesComponent implements OnInit {
  @Input() entityType: string;
  @Input() domesticState: number;
  @Input() foreignStates: number[] = [];
  @Input() showOptOutToggle: boolean = true;
  @Input() isExternal: boolean = false;
  @Input() qualificationDate: Date;

  defaultDate = new Date(new Date().getFullYear()-4, 0, 1);
  chipsArray: State[] = [];
  form: FormGroup = new FormGroup({
    states: new FormArray([]),
    selected: new FormControl([]),
    foreignStatesRequired: new FormControl(false),
  });
  states: State[];
  maxDate = new Date();
  constructor(
    private _constants: ConstantsService,
    private _confirmationService: ConfirmationService,
  ) {}

  get statesArray() {
    return this.form.controls['states'] as FormArray;
  }

  get foreignStatesRequired() {
    return this.form.get('foreignStatesRequired');
  }

  get selected() {
    return this.form.get('selected');
  }

  ngOnInit(): void {
    this._constants.states
      .pipe(
        filter((states) => states.length > 0),
        untilDestroyed(this),
      )
      .subscribe((states) => {
        this.states = states.filter(
          (stateVar) =>
            stateVar.id !== this.domesticState &&
            !this.foreignStates.find((id) => id === stateVar.id),
        );
        if (this.statesArray.value.length > 0) {
          this.selected?.setValue(this.statesArray.value.map((stateObj) => stateObj.state.id));
        }
      });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });

    this.foreignStatesRequired?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        if (this.selected?.value.length > 0) {
          this._confirmationService.confirm({
            key: 'confirmForeignRegistrationStates',
            message: 'Are you sure?  All data entered for foreign registration will be lost.',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: `Yes, I'm sure`,
            accept: () => {
              this.statesArray.clear();
              this.selected?.setValue([]);
              this.foreignStatesRequired?.setErrors(null);
              this.chipsArray = [];
            },
            rejectLabel: 'Cancel',
            reject: () => {
              this.foreignStatesRequired?.setValue(false);
            },
          });
        }
      } else {
        if (this.selected?.value.length === 0) {
          this.foreignStatesRequired?.setErrors(null);
          this.statesArray.clear();
          this.chipsArray = [];
        }
      }
    });
  }

  addState(state: State, questionOrDate?: any) {
    const stateControl = new FormGroup({
      state: new FormControl(state),
    });
    if (this.isExternal) {
      stateControl.addControl('qualificationDate', new FormControl(questionOrDate));
    } else {
      stateControl.addControl('questions', new FormControl(questionOrDate));
    }
    this.statesArray.push(stateControl);
    this.chipsArray.push(state);
  }

  removeState(id: number) {
    const index = this.statesArray.value.findIndex((stateObj) => stateObj.state.id === id);
    this.statesArray.removeAt(index);
    this.selected?.setValue(this.selected?.value.filter((state) => state !== id));
    this.chipsArray = this.chipsArray.filter((state) => state.id !== id);
  }

  handleSelect(event) {
    if (event.value.includes(event.itemValue)) {
      const foundState = this.states.find((state) => state.id === event.itemValue);
      if (foundState) {
        this.addState(foundState);
      }
    } else {
      this.removeState(event.itemValue);
    }
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    if (value) {
      for (const stateObj of value?.states) {
        this.addState(stateObj.state, stateObj.questions || stateObj.qualificationDate || null);
        const selectedValue = this.selected?.value || [];
        this.selected?.setValue([...selectedValue, stateObj.state.id]);
      }
    }
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { foreignRegistrationStates: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
    isDisabled ? this.foreignStatesRequired?.disable() : this.foreignStatesRequired?.enable();
  }
}
