<div [formGroup]="form">
  <div class="mt-3">
    <h3>Arizona</h3>
  </div>
  <div [formGroupName]="0" class="mt-3">
    <div>
      {{ form.controls[0].value.question }}
    </div>
  </div>
  <div [formGroupName]="1" class="mt-3">
    <label for="q-1" class="mr-1">
      {{ form.controls[1].value.question }}
    </label>
    <p-selectButton
      id="q-1"
      [options]="selectOptions"
      optionLabel="name"
      optionValue="value"
      [formControl]="form['controls'][1]['controls']['answer']"
    ></p-selectButton>
  </div>
  <div [formGroupName]="2">
    <label for="q-2">
      {{ form.controls[2].value.question }}
    </label>
    <p-selectButton
      id="q-2"
      [options]="selectOptions"
      optionLabel="name"
      optionValue="value"
      [formControl]="form['controls'][2]['controls']['answer']"
    ></p-selectButton>
  </div>
  <div [formGroupName]="3">
    <label for="q-3">
      {{ form.controls[3].value.question }}
    </label>
    <p-selectButton
      id="q-3"
      [options]="selectOptions"
      optionLabel="name"
      optionValue="value"
      [formControl]="form['controls'][3]['controls']['answer']"
    ></p-selectButton>
  </div>
  <div [formGroupName]="4">
    <label for="q-4">
      {{ form.controls[4].value.question }}
    </label>
    <p-selectButton
      id="q-4"
      [options]="selectOptions"
      optionLabel="name"
      optionValue="value"
      [formControl]="form['controls'][4]['controls']['answer']"
    ></p-selectButton>
  </div>
</div>
