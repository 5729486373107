<div [formGroup]="form">
  <div class="mt-3">
    <h3>Alaska</h3>
  </div>
  <div [formGroupName]="0" class="mt-3">
    <div class="mb-3">{{ form.controls[0].value.question }}</div>
    <app-address-input
      [entityType]="entityType"
      [formControl]="form.controls[0]['controls'].answer"
    ></app-address-input>
    <div class="mt-3">
      <label for="q-1">{{ form.controls[1].value.question }}</label>
      <div
        class="mt-3"
        id="q-1"
        *ngFor="let control of form.controls[1]['controls'].answer['controls']; let i = index"
      >
        <app-address-input
          [entityType]="entityType"
          [formControl]="control['controls'].address"
        ></app-address-input>
        <div class="mt-3">
          <span class="p-float-label">
            <input
              id="percentageOwned"
              [formControl]="control['controls'].percentageOwned"
              type="number"
              pInputText
            />
            <label for="percentageOwned">Percentage Owned</label>
          </span>
        </div>
        <div class="flex mt-3 justify-content-end">
          <button
            pButton
            class="p-button-outlined mr-1"
            label="Remove Officer"
            *ngIf="i > 0"
            (click)="removeAdditionalAddress(i)"
          ></button>
          <button pButton label="Add Additional Officer" (click)="addAdditionalAddress()"></button>
        </div>
      </div>
    </div>
  </div>
</div>
