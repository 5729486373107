<ng-container *ngIf="loadingPlans; else showPlans">
  <div class="h-full w-full flex justify-content-center align-items-center">
    <p-progressSpinner strokeWidth="4"></p-progressSpinner>
  </div>
</ng-container>
<ng-template #showPlans [formGroup]="form">
  <div class="grid justify-content-center">
    <ng-container *ngFor="let plan of plans; let i = index">
      <div
        (click)="selectPlan(plan)"
        class="col-12 lg:col-4"
        *ngIf="contactInformation.ssn || (!contactInformation.ssn && i === 2) || hideEinBullet"
      >
        <div class="p-3 h-full price-card">
          <div
            class="shadow-2 p-3 h-full flex flex-column price--container"
            [ngClass]="optionSelected.value?.label === plan.label ? 'selected' : ''"
          >
            <div class="text-900 font-medium text-xl mb-2">
              {{ plan.label }}
            </div>
            <div class="text-600">{{ plan.description }}</div>
            <hr class="my-3 mx-0 border-top-1 border-bottom-none border-300" />
            <div class="flex align-items-center">
              <span class="font-bold text-2xl text-900"> ${{ plan.price / 100 }} </span>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-bottom-none border-300" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3" *ngFor="let bullet of plan.bullets">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>{{ bullet }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="grid" *ngIf="selectedPlan">
    <div class="col-12 flex justify-content-center">
      <div class="box--container w-12 lg:w-8 selected-plan-card">
        <div class="col-8">
          <div class="p-inputgroup">
            <span class="p-float-label">
              <input id="discount" type="text" formControlName="discountCode" pInputText />
              <label for="discount"> Discount Code</label>
            </span>
            <p-button
              class="p-button-outlined button--discount"
              (click)="getCoupon()"
              label="Apply"
            ></p-button>
          </div>
          <p *ngIf="codeInvalid" class="error-message">This code is invalid.</p>
        </div>
        <h3>Selected plan: {{ selectedPlan.label }}</h3>
        <hr />
        <h4>Registration Fees*</h4>
        <ng-container *ngFor="let lineItem of selectedPlan.prices">
          <div class="p-col-12 w-full">
            <div class="invoice-list-item flex w-full justify-content-between">
              <div class="invoice-list-detail">
                <div class="flex flex-column">
                  <span class="pb-1">{{ lineItem.description }}</span>
                </div>
              </div>
              <div class="invoice-price">
                <div class="flex flex-column align-items-end mb-2">
                  <span class="pb-1">${{ (lineItem.unit_amount + 9500) / 100 }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <hr />
        <ng-container *ngIf="form.controls.coupon.value">
          <div class="w-full flex justify-content-between">
            <h4>Discounts</h4>
            <div class="invoice-price">
              <div class="flex flex-column align-items-end mb-2">
                <span class="pb-1">-${{ (form.controls.discount.value / 100).toFixed(2) }}</span>
              </div>
            </div>
          </div>
          <hr />
        </ng-container>
        <div class="w-full flex justify-content-between">
          <h4 class="inline">Total:</h4>
          <ng-container *ngIf="loading; else showTotal">
            <p-progressSpinner strokeWidth="4" class="total-spinner"></p-progressSpinner>
          </ng-container>
          <ng-template #showTotal>
            <h4 class="inline">
              ${{
                ((totalCost || totalCost === 0 ? totalCost : selectedPlan.price) / 100).toFixed(2)
              }}
            </h4>
          </ng-template>
        </div>
        <hr />
        <div class="p-col-12 w-full">
          <div class="text-sm">
            <!-- TODO - make RAF dynamic, pull from database -->
            *Includes ${{ 9500 / 100 }} per state Registered Agent Subscription, billed annually.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!form.valid && showError" class="flex justify-content-center">
    You must select a pricing option in order to continue to the payment page.
  </div>
</ng-template>
