<div [formGroup]="form">
  <div class="mt-3">
    <h3>Ohio</h3>
  </div>
  <div class="mt-3 flex flex-column">
    <label for="q-0" class="mt-3">{{
      this.form.controls[0].value.question
    }}</label>

    <p-radioButton
      id="q-0"
      name="0"
      [formControl]="this.form.controls[0]['controls'].answer"
      [value]="true"
      label="Yes"
    ></p-radioButton>
    <p-radioButton
      id="q-0"
      class="mt-3"
      name="0"
      [formControl]="this.form.controls[0]['controls'].answer"
      [value]="false"
      label="No"
    ></p-radioButton>
  </div>
  <ng-container *ngIf="this.form.controls[0].value.answer">
    <label for="q-1" class="mt-3">
      {{ this.form.controls[1].value.question }}
    </label>
    <ng-container *ngFor="let option of options">
      <p-radioButton
        name="1"
        id="q-1"
        [formControl]="this.form.controls[1]['controls'].answer"
        [value]="option"
        [label]="option"
      ></p-radioButton>
    </ng-container>
  </ng-container>
</div>
