<app-header [rightMenuConfig]="headerMenuItems"></app-header>
<app-landing-wrapper [suppressSidebar]="true">
  <div content>
    <div class="mb-5">
      <h2 class="text-900 text-3xl font-medium mb-3">Welcome</h2>
      <span class="text-600 font-medium mr-2">Don't have an account?</span>
      <a
        class="font-medium no-underline text-blue-500 cursor-pointer"
        data-qa="register-link"
        routerLink="/register"
        >Start my business!</a
      >
    </div>

    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <span class="p-float-label mb-3">
        <input
          id="email"
          formControlName="email"
          autocomplete="username"
          type="text"
          pInputText
          data-qa="email-input"
        />
        <label for="email">Email</label>
      </span>

      <span class="p-float-label">
        <input
          id="password"
          formControlName="password"
          type="password"
          autocomplete="current-password"
          data-qa="password-input"
          pInputText
        />
        <label for="password">Password</label>
      </span>

      <a
        class="block mb-3 mt-2"
        routerLink="/forgot-password"
        data-qa="forgot-password-link"
        >Forgot your password?</a
      >
      <button
        pButton
        label="Sign In"
        type="submit"
        [loading]="loading"
        data-qa="login-button"
      ></button>
    </form>
    <div class="mt-4">
      <hr class="mb-4" />
      <h4>Or, sign in with ...</h4>
      <img
        (click)="googleSigninPopup()"
        type="button"
        alt="logo"
        src="assets/images/btn_google_signin_dark_normal_web.png"
      />
    </div>
  </div>
</app-landing-wrapper>
<app-footer></app-footer>
