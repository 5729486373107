<div [formGroup]="form">
  <div class="mt-3">
    <h3>New Hampshire</h3>
  </div>
  <div>Choose one of the following:</div>
  <div *ngFor="let option of options" class="mt-3 flex">
    <p-radioButton
      id="questionRadio"
      [value]="option.question"
      [formControl]="this.form.controls[0]['controls'].question"
      (onClick)="populateSubQuestion(option.subQuestion)"
      class="mr-3"
    >
    </p-radioButton>
    <label for="questionRadio">{{ option.question }}</label>
  </div>

  <div *ngIf="form.controls[1]['controls']" class="mt-4">
    <label for="questionThree">{{
      form.controls[1]["controls"].question.value
    }}</label>
    <ng-container *ngIf="isQuestionThree; else showTextInput">
      <span class="p-float-label">
        <p-calendar
          appendTo="body"
          id="questionThree"
          [formControl]="form.controls[1]['controls'].answer"
          data-qa="questionThree-input"
          class="w-full"
        ></p-calendar>
        <label for="questionThree">Registration Date</label>
      </span>
      <p class="error-message">
        {{
          form.errors?.conditionallyRequired ? "This field is required." : ""
        }}
      </p>
    </ng-container>
    <ng-template #showTextInput>
      <span class="p-float-label">
        <input
          id="questionThree"
          [formControl]="form.controls[1]['controls'].answer"
          type="text"
          pInputText
        />
        <p class="error-message">
          {{
            form.errors?.conditionallyRequired ? "This field is required." : ""
          }}
        </p>
      </span>
    </ng-template>
  </div>
</div>
