import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  ValidationErrors,
} from '@angular/forms';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  take,
  debounceTime,
  finalize,
} from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

export function notBeforeDate(c: FormControl) {
  return DateTime.fromJSDate(c.value).startOf('day') <=
    DateTime.now().startOf('day')
    ? null
    : {
        notBeforeDate: {
          valid: false,
        },
      };
}

export function doesEmailExist(authService: AuthService): AsyncValidatorFn {
  return (
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      take(1),
      switchMap(() => {
        return authService.isEmailAvailable(control.value);
      }),
      map((response: { isEmailAvailable: boolean }) => {
        return response.isEmailAvailable ? null : { emailIsTaken: true };
      }),
      finalize(() => {
        control.root.updateValueAndValidity({ onlySelf: true });
      })
    );
  };
}
