import { Component, forwardRef, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-wisconsin-corp-questions',
  templateUrl: './wisconsin-corp-questions.component.html',
  styleUrls: ['./wisconsin-corp-questions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WisconsinCorpQuestionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WisconsinCorpQuestionsComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class WisconsinCorpQuestionsComponent implements OnInit {
  form = new FormGroup({
    0: new FormGroup({
      question: new FormControl(
        `The application will include an Estimated 1st Year Activity Worksheet. This will need to be completed by the corporation due to the specific and financial nature of the information being requested. Please check this box to confirm acknowledgment of this requirement.`
      ),
      answer: new FormControl(null, Validators.required),
    }),
  });
  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
    setTimeout(() => {
      this.form.updateValueAndValidity();
    }, 0);
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { foreignRegistrationStates: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
