import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TriggerValidationService {
  public triggerValidation$ = new Subject();

  constructor() {}

  triggerValidation() {
    this.triggerValidation$.next();
  }
}
