import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@types';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser: User;

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: NotificationService
  ) {}

  createUser(firstName: string, lastName, email: string, password: string) {
    return this._httpClient
      .post(`${environment.apiUrl}/auth/register`, {
        firstName,
        lastName,
        email,
        password,
      })
      .pipe(
        catchError((response: any) => {
          this._notificationService.showErrorNotification(
            'An error occured while trying to register.'
          );
          return throwError(response);
        }),
        tap(() => {
          this._notificationService.showSuccessNotification(
            'Successfully registered!'
          );
        })
      );
  }

  getCurrentUser() {
    return this._httpClient.get<User>(`${environment.apiUrl}/auth/me`).pipe(
      tap((response) => {
        this.currentUser = response;
      })
    );
  }

  isEmailAvailable(email: string) {
    return this._httpClient.post<{ isEmailAvailable: boolean }>(
      `${environment.apiUrl}/auth/email-available`,
      {
        email,
      }
    );
  }
}
