import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-choose-add-or-existing-registration',
  templateUrl: './choose-add-or-existing-registration.component.html',
  styleUrls: ['./choose-add-or-existing-registration.component.scss'],
})
export class ChooseAddOrExistingRegistrationComponent implements OnInit {
  isCurrentUser: boolean = false;
  // Config for our header navigation
  headerMenuItems: MenuItem[] = [
    {
      title: 'Sign In',
      routerLink: ['/login'],
      styleClass: 'p-button-outlined',
    },
    {
      title: 'Create Account',
      routerLink: ['/create-account'],
      styleClass: 'p-button-outlined dense',
    },
  ];

  selectedOption: 'new' | 'existing';
  contactInfo: any = null;

  constructor(private _router: Router, private _route: ActivatedRoute, private _fbAuth: Auth) {
    this.isCurrentUser = !!_fbAuth.currentUser;
  }
  ngOnInit(): void {
    this.contactInfo = history.state;
  }

  continue(): void {
    if (!this.selectedOption) {
      return;
    }
    this._router.navigate(
      [`${this.isCurrentUser ? '/dashboard' : ''}/register`, this.selectedOption],
      {
        state: this.contactInfo,
      },
    );
  }
}
