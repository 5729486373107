<app-header [rightMenuConfig]="headerMenuItems"></app-header>
<app-landing-wrapper [suppressSidebar]="true">
  <div content>
    <h2 class="text-900 text-3xl font-medium mb-3">Forgot Password</h2>
    <span class="text-600 font-medium mr-2">Remember your password?</span>
    <a
      class="font-medium no-underline text-blue-500 cursor-pointer"
      data-qa="register-link"
      routerLink="/login"
      >Sign In.</a
    >
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="resetPassword()">
      <label for="email">Email</label>
      <input
        id="email"
        formControlName="email"
        type="text"
        pInputText
        data-qa="reset-password-input-email"
      />

      <button
        pButton
        type="submit"
        [disabled]="forgotPasswordForm.invalid"
        label="Reset Password"
        class="mt-6"
        data-qa="reset-password-button-submit"
      ></button>
    </form>
  </div>
</app-landing-wrapper>
<app-footer></app-footer>
