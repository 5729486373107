import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { DeactivateGuard } from 'src/app/guards/deactivate.guard';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessEntityRegisterService } from 'src/app/services/business-entity-register.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { TriggerValidationService } from 'src/app/services/trigger-validation.service';
import { growAnimation } from 'src/app/utils/animations';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { FormService } from 'src/app/services/form.service';
@Component({
  selector: 'app-existing-registration-stepper',
  templateUrl: './existing-registration-stepper.component.html',
  styleUrls: ['./existing-registration-stepper.component.scss'],
  animations: [growAnimation],
  encapsulation: ViewEncapsulation.None,
})
@UntilDestroy()
export class ExistingRegistrationStepperComponent implements OnInit, OnDestroy {
  // Config for our header navigation
  headerMenuItems: MenuItem[] = [
    {
      title: 'Sign In',
      routerLink: ['/login'],
      styleClass: 'p-button-outlined',
    },
    {
      title: 'Create Account',
      routerLink: ['/create-account'],
      styleClass: 'p-button-outlined dense',
    },
  ];

  triggerPayment = new Subject<boolean>();
  showPricingError = false;

  steps = [
    {
      key: 'existingBusinessDetails',
      value: 'Existing Business Entity Details',
    },
    { key: 'domesticRegistration', value: 'Domestic Registration State' },
    {
      key: 'existingForeignRegistration',
      value: 'Existing Foreign Registration States',
    },
    { key: 'newForeignRegistration', value: 'New Foreign Registration States' },
    { key: 'contactInformation', value: 'Contact Information' },
    { key: 'accountInformation', value: 'Account Information' },
    { key: 'additionalServices', value: 'Additional Services' },
    { key: 'pricingOptions', value: 'Pricing Options' },
    { key: 'reviewPayment', value: 'Review & Payment' },
  ];

  touchedSteps: { [key: string]: boolean } = {
    existingBusinessDetails: true,
    domesticRegistration: false,
    existingForeignRegistration: false,
    newForeignRegistration: false,
    accountInformation: false,
    additionalServices: false,
    pricingOptions: false,
    reviewPayment: false,
  };

  currentStep = 'existingBusinessDetails';

  progress = 0;

  isLoading = false;
  showPage = false;
  existingForeignStates: number[] = [];
  form: FormGroup = new FormGroup({
    existingBusinessDetails: new FormControl(null, Validators.required),
    domesticRegistration: new FormControl(null, Validators.required),
    existingForeignRegistration: new FormControl(null, Validators.required),
    newForeignRegistration: new FormControl(null, Validators.required),
    contactInformation: new FormControl(null, Validators.required),
    accountInformation: new FormControl(null, Validators.required),
    additionalServices: new FormControl(null),
    pricingOptions: new FormControl(null, Validators.required),
    reviewPayment: new FormControl(null, Validators.required),
  });
  contactInfo: any = {};
  isCurrentUser: boolean = false;
  payTotal = 0;

  beforeUnloadListener = (e: BeforeUnloadEvent) => {
    if (this.form.invalid && this.form.touched) {
      e.preventDefault();
      e.returnValue = '';
    }
  };
  constructor(
    private _analytics: AnalyticsService,
    private _constants: ConstantsService,
    private _triggerValidation: TriggerValidationService,
    private _auth: AuthService,
    private _fbAuth: Auth,
    private _deactGuard: DeactivateGuard,
    private _businessEntityRegisterService: BusinessEntityRegisterService,
    private _notificationService: NotificationService,
    private _router: Router,
    public _formService: FormService,
  ) {
    this._constants.getStates().subscribe();
    this._constants.getTitles().subscribe();
    this._constants.getIndustries().subscribe();
    this._constants.getServices().subscribe();
    this.isCurrentUser = !!this._fbAuth.currentUser;
  }

  get currentStepIndex() {
    return this.steps.findIndex((step) => step.key === this.currentStep);
  }

  ngOnInit(): void {
    this.contactInfo = history.state;
    window.addEventListener('beforeunload', this.beforeUnloadListener);
    if (this.contactInfo?.primaryContact) {
      const contactInfo = {
        ...this.contactInfo.primaryContact,
        state: this.contactInfo.primaryContact.stateId,
        dateOfBirth: this._auth.currentUser.dateOfBirth
          ? new Date(this._auth.currentUser.dateOfBirth)
          : '',
      };
      this.form.get('contactInformation')?.setValue(contactInfo);
    }

    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe((status) => {
      if (status === 'INVALID' && this.form.touched) {
        this._deactGuard.canDeact = false;
      }

      if (status === 'VALID') {
        this._deactGuard.canDeact = true;
      }
    });
    setTimeout(() => (this.showPage = true), 500);
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  }

  // Jump to a specific step
  jumpToStep(step: string) {
    if (this.touchedSteps[step]) {
      this.currentStep = step;
    }
    // this._analytics.logEvent('custom_event', {
    //   event_category: 'register_stepper',
    //   event_action: 'jump_to_step',
    //   event_label: `${step}`,
    // });
  }

  goToPreviousStep() {
    const index = this.currentStepIndex;
    if (index > 0) {
      this.currentStep = this.steps[index - 1].key;
    }
  }

  goToNextStep() {
    if (this.form.controls[this.currentStep].invalid) {
      this._triggerValidation.triggerValidation();
      if (this.currentStep === 'pricingOptions') {
        this.showPricingError = true;
      }
      return;
    }
    if (this.currentStep === 'existingForeignRegistration') {
      this.existingForeignStates = this.form.value['existingForeignRegistration'].states.map(
        (stateObj) => stateObj.state.id,
      );
    }
    // can't progress if statesArray doesn't have any states.
    if (this.currentStep === 'newForeignRegistration') {
      const statesArray = this.form.value['newForeignRegistration'].states;
      if (statesArray.length === 0) {
        return;
      }
    }
    const index = this.currentStepIndex;
    if (index !== this.steps.length) {
      this.currentStep = this.steps[index + 1].key;
      this.touchedSteps[this.currentStep] = true;
      const progress = this.calculateProgress();
      if (this.progress <= progress) {
        this.progress = this.calculateProgress();
      }
    }
  }

  // Calculate the progress
  calculateProgress(): number {
    const index = this.currentStepIndex;
    return Math.round((index / this.steps.length) * 100);
  }

  handlePay() {
    this.triggerPayment.next();
  }

  submitForm(paymentId: string) {
    if (this.form.controls[this.currentStep].invalid) {
      this._triggerValidation.triggerValidation();
      return;
    }

    const formValues = this.form.value;
    this._deactGuard.canDeact = true;
    const registration: any = {
      businessEntity: {
        // Adding this as default to cover the areas where we look for businessNameChoice1
        businessNameChoice1: formValues['existingBusinessDetails'].name,
        name: formValues['existingBusinessDetails'].name,
        entityType: formValues['existingBusinessDetails'].entityType.code,
        industry: formValues['existingBusinessDetails'].industry,
        federalEin: formValues['existingBusinessDetails'].ein,
        qualificationDate: formValues['existingBusinessDetails'].qualificationDate,
        qualificationStatus: 'completed',
        services: formValues['additionalServices']?.map((service) => parseInt(service)) || [],
      },
      user: {
        signInEmail:
          formValues['accountInformation'].email ||
          formValues['accountInformation'].googleUser?.email,
        password: formValues['accountInformation'].password,
      },
      contactInfo: {
        title: formValues['contactInformation'].title,
        firstName: formValues['contactInformation'].firstName,
        lastName: formValues['contactInformation'].lastName,
        streetAddress1: formValues['contactInformation'].streetAddress1,
        streetAddress2: formValues['contactInformation'].streetAddress2,
        city: formValues['contactInformation'].city,
        state: formValues['contactInformation'].state,
        postalCode: formValues['contactInformation'].postalCode,
        phone: formValues['contactInformation'].phone,
        dateOfBirth: formValues['contactInformation'].dateOfBirth,
        ssn: formValues['contactInformation'].ssn,
        email: formValues['contactInformation'].email,
      },
      stateRegistrations: [
        {
          isDomestic: true,
          stateId: formValues['domesticRegistration'].domesticState,
          payrollRegistrationStatus: formValues['additionalServices']?.includes('3')
            ? 'pending'
            : 'not_applicable',
          salesTaxRegistrationStatus: formValues['additionalServices']?.includes('5')
            ? 'pending'
            : 'not_applicable',
          registrationQuestionResponses: formValues['domesticRegistration'].questions
            ? Object.values(formValues['domesticRegistration'].questions)
            : [],
          qualificationStatus: 'completed',
          qualificationDate: formValues['existingBusinessDetails'].qualificationDate,
          originalSource: 'external',
        },
        ...formValues['existingForeignRegistration'].states.map((stateObj) => {
          return {
            isDomestic: false,
            stateId: parseInt(stateObj.state.id),
            payrollRegistrationStatus: formValues['additionalServices']?.includes('3')
              ? 'pending'
              : 'not_applicable',
            salesTaxRegistrationStatus: formValues['additionalServices']?.includes('5')
              ? 'pending'
              : 'not_applicable',
            registrationQuestionResponses: stateObj.questions
              ? Object.values((stateObj as any).questions)
              : [],
            qualificationStatus: 'completed',
            qualificationDate:
              (stateObj as any).qualificationDate ||
              formValues['existingBusinessDetails'].qualificationDate,
            originalSource: 'external',
          };
        }),
        ...formValues['newForeignRegistration'].states.map((stateObj) => {
          return {
            isDomestic: false,
            stateId: parseInt(stateObj.state.id),
            payrollRegistrationStatus: formValues['additionalServices']?.includes('3')
              ? 'pending'
              : 'not_applicable',
            salesTaxRegistrationStatus: formValues['additionalServices']?.includes('5')
              ? 'pending'
              : 'not_applicable',
            registrationQuestionResponses: stateObj.questions
              ? Object.values((stateObj as any).questions)
              : [],
          };
        }),
      ],
      payment: {
        paymentId,
        invoiceId: this.form.value['pricingOptions'].selectedInvoice.id,
      },
    };

    if (formValues['existingBusinessDetails'].entityType.code === 'corporation') {
      registration.contactInfo.isDirector = formValues['contactInformation'].isDirector;
    }

    this.isLoading = true;
    this._businessEntityRegisterService.registerBusinessEntityAndAccount(registration).subscribe({
      next: async (businessEntity) => {
        if (
          !formValues['accountInformation'].googleUser &&
          formValues['accountInformation'].password
        ) {
          await signInWithEmailAndPassword(
            this._fbAuth,
            formValues['accountInformation'].email,
            formValues['accountInformation'].password,
          );
        }
        this._notificationService.showSuccessNotification(
          'Successfully created your account and business!',
        );
        this._router.navigate(['/dashboard']);
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this._deactGuard.canDeact = false;
      },
    });
  }
}
