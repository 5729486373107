import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { doesEmailExist } from 'src/app/shared/validators';
import { ERROR_CODE_MAP, setErrors } from 'src/app/utils/form-errors';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { AnalyticsService } from 'src/app/services/analytics.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  loading = false;
  registerForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl(
      '',
      [Validators.required, Validators.email],
      doesEmailExist(this._auth)
    ),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      this.serverValidation('password', []),
    ]),
  });

  headerMenuItems: MenuItem[] = [
    {
      title: 'Sign In',
      routerLink: ['/login'],
      styleClass: 'p-button-text',
    },
    {
      title: 'Create Account',
      routerLink: ['/register'],
      styleClass: 'p-button-outlined dense',
    },
  ];

  serverValidation(key: string, errors: any[]) {
    return (control: AbstractControl) => {
      return null;
    };
  }

  constructor(
    private _auth: AuthService,
    private _fbAuth: Auth,
    private _router: Router,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {}

  async register() {
    this.loading = true;
    const { firstName, lastName, email, password } = this.registerForm.value;
    this._auth.createUser(firstName, lastName, email, password).subscribe(
      async () => {
        await signInWithEmailAndPassword(this._fbAuth, email, password);
        this.loading = false;
        this.analytics.logEvent('register_success', {
          event_name: 'register_success',
          event_category: 'register',
          event_label: 'Successful registration',
        });
        this._router.navigate(['/dashboard']);
      },
      (response) => {
        this.loading = false;
        setErrors(response.error.message, this.registerForm);
      }
    );
  }
}
