<div class="review--section mb-3" [formGroup]="parentFormGroup">
  <p-accordion>
    <p-accordionTab header="Business Name, Entity Type & Industry">
      <app-existing-name-and-type
        formControlName="existingBusinessDetails"
        suppressValidationMessage
      ></app-existing-name-and-type>
    </p-accordionTab>
    <p-accordionTab header="Domestic State Registration">
      <!-- Telling the component "llc" to not show questions -->
      <app-domestic-registration-state
        entityType="llc"
        suppressValidationMessage
        formControlName="domesticRegistration"
      ></app-domestic-registration-state>
    </p-accordionTab>
    <p-accordionTab header="Existing Foreign Registrations">
      <app-foreign-registration-states
        suppressValidationMessage
        [entityType]="parentFormGroup.value['existingBusinessDetails'].entityType.code"
        [domesticState]="parentFormGroup.value['domesticRegistration'].domesticState"
        [isExternal]="true"
        formControlName="existingForeignRegistration"
      ></app-foreign-registration-states>
    </p-accordionTab>
    <p-accordionTab header="New Foreign Registrations">
      <app-foreign-registration-states
        suppressValidationMessage
        [entityType]="parentFormGroup.value['existingBusinessDetails'].entityType.code"
        [domesticState]="parentFormGroup.value['domesticRegistration'].domesticState"
        [showOptOutToggle]="false"
        formControlName="newForeignRegistration"
      ></app-foreign-registration-states>
    </p-accordionTab>
    <p-accordionTab header="Contact Information">
      <app-contact-information
        [entityType]="parentFormGroup.value['existingBusinessDetails'].entityType.code"
        suppressValidationMessage
        formControlName="contactInformation"
      ></app-contact-information>
    </p-accordionTab>
    <p-accordionTab header="Account Information">
      <span class="p-float-label">
        <input
          id="email"
          [value]="
            parentFormGroup.value['accountInformation'].email ||
            parentFormGroup.value['accountInformation'].googleUser?.email
          "
          type="text"
          disabled
          pInputText
        />
        <label for="email">Email</label>
      </span>
    </p-accordionTab>
    <p-accordionTab header="Selected Services">
      <app-recommended-services
        suppressValidationMessage
        formControlName="additionalServices"
        [disableForm]="true"
        [hideEin]="true"
      ></app-recommended-services>
    </p-accordionTab>
  </p-accordion>
</div>
<div class="grid">
  <div class="col-12">
    <div class="box--container selected-plan-card">
      <div class="text-900 font-semibold text-xl mb-2">
        {{ selectedPlan.label }}
      </div>
      <div class="text-600 mb-2">
        You selected a {{ selectedPlan.label }} plan, which includes the following services to keep
        you business running smooth:
      </div>
      <ul class="list-none p-0 m-0 flex-grow-1 mb-2">
        <li class="flex align-items-center mb-3" *ngFor="let bullet of selectedPlan.bullets">
          <i class="pi pi-check-circle text-green-500 mr-2"></i>
          <span>{{ bullet }}</span>
        </li>
      </ul>
      <hr />
      <h4>Registration Fees*</h4>
      <ng-container *ngFor="let lineItem of selectedPlan.prices">
        <div class="p-col-12 w-full">
          <div class="invoice-list-item flex w-full justify-content-between">
            <div class="invoice-list-detail">
              <div class="flex flex-column">
                <span class="pb-1">{{ lineItem.description }}</span>
              </div>
            </div>
            <div class="invoice-price">
              <div class="flex flex-column align-items-end mb-2">
                <span class="pb-1">${{ (lineItem.unit_amount + 9500) / 100 }}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <hr />
      <div class="w-full flex justify-content-between">
        <h4 class="inline">Total:</h4>
        <h4 class="inline" *ngIf="selectedPlan">
          ${{ (selectedInvoice?.total + selectedPlan?.registeredAgentFee) / 100 }}
        </h4>
      </div>
      <hr />
      <div class="p-col-12 w-full">
        <div class="text-sm">
          *Includes $95 per state Registered Agent Subscription, billed annually.
        </div>
      </div>
    </div>
  </div>
</div>
<div class="text-xl mb-2 mt-3">Payment</div>
<div class="text-600 mb-2">
  Your business registration cannot be processed until a payment has been successfully submitted
</div>
<div class="col-12">
  <ngx-stripe-card
    [elementsOptions]="elementsOptions"
    [options]="cardOptions"
    containerClass="p-inputtext p-component p-element"
  ></ngx-stripe-card>
</div>
<div [formGroup]="form">
  <div class="grid">
    <div class="col-1">
      <p-checkbox
        inputId="termsAndConditions"
        class="inline"
        [binary]="true"
        formControlName="termsAndConditions"
      ></p-checkbox>
    </div>
    <div class="col px-0">
      <label for="termsAndConditions"
        >I accept the
        <button
          pButton
          class="p-button-link p-0 box-shadow-none"
          type="button"
          (click)="termsAndConditionsDisplay = true"
          label="Terms and Conditions"
        ></button
        >.</label
      >
    </div>
  </div>
  <div class="grid">
    <div class="col-1">
      <p-checkbox
        inputId="registeredAgent"
        class="inline"
        [binary]="true"
        formControlName="registeredAgent"
      ></p-checkbox>
    </div>
    <div class="col px-0">
      <label for="registeredAgent"
        >I accept VCorp as my
        <button
          pButton
          class="p-button-link p-0 box-shadow-none"
          type="button"
          (click)="registeredAgentDisplay = true"
          label="Registered Agent"
        ></button
        >.</label
      >
    </div>
  </div>
  <div class="grid">
    <div class="col-1">
      <p-checkbox
        inputId="signingAgreement"
        class="inline"
        [binary]="true"
        formControlName="signingAgreement"
      ></p-checkbox>
    </div>
    <div class="col px-0">
      <label for="signingAgreement"
        >I agree to have Integrated Stack
        <button
          pButton
          class="p-button-link p-0 box-shadow-none"
          type="button"
          (click)="signingAgreementDisplay = true"
          label="sign my business registration papers on my behalf"
        ></button
        >.</label
      >
    </div>
  </div>
</div>
<p-dialog
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
  [modal]="true"
  header="Terms and Conditions"
  [(visible)]="termsAndConditionsDisplay"
>
  <ng-template pTemplate="content">
    <div class="p-3"><app-t-and-c-copy></app-t-and-c-copy></div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="termsAndConditionsDisplay = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
  [modal]="true"
  header="Registered Agent Terms"
  [(visible)]="registeredAgentDisplay"
>
  <ng-template pTemplate="content">
    <div class="p-3">
      I agree to enroll in the Integrated Stack Registered Agent (RA) subscription service. The
      Integrated Stack Registered Agent subscription service renews annually. I agree to have my
      card automatically charged $95 per registration when the order is submitted. Renewal rates are
      subject to change. You can cancel the subscription at any time by contacting us at
      <a href="mailto:support@IntegratedStack.com">support@IntegratedStack.com</a>. To cancel the
      subscription, you will need to first appoint a new Registered Agent.
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="registeredAgentDisplay = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
  header="Signature Terms"
  [modal]="true"
  [(visible)]="signingAgreementDisplay"
>
  <ng-template pTemplate="content">
    <div class="p-3">
      I am an Authorized Agent for my company. As an Authorized Agent, I agree to appoint Integrated
      Stack, Inc. as an authorized representative and/or signer under the laws of my entity's state
      of formation or state of foreign qualification, and I grant Integrated Stack Inc a limited
      power of attorney, solely for the purposes of foreign qualifying my entity in the
      jurisdictions I have ordered.
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="signingAgreementDisplay = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
