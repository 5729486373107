import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EntityType } from '@types';

@Component({
  selector: 'app-massachusetts-llc-questions',
  templateUrl: './massachusetts-llc-questions.component.html',
  styleUrls: ['./massachusetts-llc-questions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MassachusettsLlcQuestionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MassachusettsLlcQuestionsComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class MassachusettsLlcQuestionsComponent implements OnInit {
  @Input() entityType: EntityType;

  form = new FormGroup({
    0: new FormGroup({
      question: new FormControl('Authorization Provided?'),
      answer: new FormControl(null, Validators.required),
    }),
    1: new FormGroup({
      question: new FormControl(
        `Name and business address of each person authorized to execute, acknowledge, deliver, and record any recordable instrument purporting to affect an interest in real property recorded with a registry of deeds or district office of the land court.`,
      ),
      answer: new FormArray([new FormControl(null)]),
    }),
  });

  authorized = new FormControl();
  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });

    const first = this.form.get('0');
    first
      ?.get('answer')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        console.log('value', value);
        if (first.get('answer')?.value === 'none') {
          const secondAnswer = this.form.get('1')?.get('answer') as FormArray;
          console.log('second', secondAnswer);
          secondAnswer?.clear();
          secondAnswer.push(new FormControl(null));
        }
      });
    setTimeout(() => {
      this.form.updateValueAndValidity();
    }, 0);
  }

  addAdditionalAddress() {
    this.form.controls[1]['controls'].answer.push(new FormControl());
  }

  removeAdditionalAddress(index: number) {
    const answer = this.form.controls[1]['controls'].answer as FormArray;
    answer.removeAt(index);
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    // add addresses to form array.  needed if returning to step in stepper
    if (value && value[1]?.answer.length > 1) {
      value[1].answer.forEach((answer: any, index: number) => {
        if (index > 0) {
          this.form.controls[1]['controls'].answer.push(new FormControl());
        }
      });
    }
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { foreignRegistrationStates: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
