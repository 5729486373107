<div [formGroup]="form">
  <div class="flex align-items-center mb-5">
    <p-confirmDialog
      header="Confirm Remove All"
      icon="pi pi-exclamation-triangle"
      [position]="'top'"
      [key]="'confirmForeignRegistrationStates'"
    ></p-confirmDialog>
    <ng-container *ngIf="showOptOutToggle">
      <p-inputSwitch
        inputId="foreignStatesRequired"
        formControlName="foreignStatesRequired"
        class="mr-3"
        suppressValidationMessage
      >
      </p-inputSwitch>
      <label for="foreignStatesRequired" class="inline switch--label">
        {{
          isExternal
            ? "I'm not registered in any additional states."
            : 'I don’t need to register in any additional states.'
        }}</label
      >
    </ng-container>
  </div>
  <div>
    <span class="p-float-label" *ngIf="!foreignStatesRequired?.value">
      <p-multiSelect
        appendTo="body"
        [options]="states"
        formControlName="selected"
        (onChange)="handleSelect($event)"
        optionLabel="name"
        optionValue="id"
        id="foreignStateSelection"
        [disabled]="form.disabled"
      ></p-multiSelect>
      <label for="foreignStateSelection">Foreign State Registrations</label>
    </span>
  </div>
  <p-chip
    *ngFor="let state of chipsArray"
    class="mt-3 mr-3 inline-block"
    [label]="state.name"
    [removable]="!form.disabled"
    (onRemove)="removeState(state.id)"
  ></p-chip>
  <hr />
  <div>
    <ng-container *ngFor="let selected of statesArray.controls">
      <ng-container *ngIf="isExternal; else showQuestions">
        <div class="mt-3 mb-2">
          <h3>{{ selected.value.state?.name }}</h3>
        </div>
        <span class="p-float-label">
          <!-- This element should support year selection but currently does not. -->
          <p-calendar
            appendTo="body"
            inputid="qualificationDate"
            [formControl]="selected['controls'].qualificationDate"
            data-qa="qualificationDate-input"
            [maxDate]="maxDate"
            class="w-full"
            [defaultDate]="defaultDate"
          ></p-calendar>
          <label for="qualificationDate">What is your qualification date?</label>
        </span>
      </ng-container>
      <ng-template #showQuestions>
        <!-- Corporation Questions -->
        <ng-container *ngIf="entityType === 'corporation'">
          <!-- Questions for Alaska -->
          <ng-container *ngIf="selected.value.state?.name === 'Alaska'">
            <app-alaska-corp-questions
              [formControl]="selected['controls'].questions"
              [entityType]="entityType"
            ></app-alaska-corp-questions>
          </ng-container>
          <!-- Arizona -->
          <ng-container *ngIf="selected.value.state?.name === 'Arizona'">
            <app-arizona-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-arizona-corp-questions>
          </ng-container>
          <!-- Arkansas -->
          <ng-container *ngIf="selected.value.state?.name === 'Arkansas'">
            <app-arkansas-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-arkansas-corp-questions>
          </ng-container>
          <!-- Connecticut -->
          <ng-container *ngIf="selected.value.state?.name === 'Connecticut'">
            <app-connecticut-corp-questions
              [formControl]="selected['controls'].questions"
              [entityType]="entityType"
            ></app-connecticut-corp-questions>
          </ng-container>
          <!-- Illinois -->
          <ng-container *ngIf="selected.value.state?.name === 'Illinois'">
            <app-illinois-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-illinois-corp-questions>
          </ng-container>
          <!-- New Hampshire -->
          <ng-container *ngIf="selected.value.state?.name === 'New Hampshire'">
            <app-new-hampshire-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-new-hampshire-corp-questions>
          </ng-container>
          <!-- New Mexico -->
          <ng-container *ngIf="selected.value.state?.name === 'New Mexico'">
            <app-new-mexico-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-new-mexico-corp-questions>
          </ng-container>
          <!-- New York -->
          <ng-container *ngIf="selected.value.state?.name === 'New York'">
            <app-new-york-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-new-york-corp-questions>
          </ng-container>
          <!-- Ohio -->
          <ng-container *ngIf="selected.value.state?.name === 'Ohio'">
            <app-ohio-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-ohio-corp-questions>
          </ng-container>
          <!-- Oklahoma -->
          <ng-container *ngIf="selected.value.state?.name === 'Oklahoma'">
            <app-oklahoma-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-oklahoma-corp-questions>
          </ng-container>
          <!-- Pennsylvania -->
          <ng-container *ngIf="selected.value.state?.name === 'Pennsylvania'">
            <app-pennsylvania-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-pennsylvania-corp-questions>
          </ng-container>
          <!-- Rhode Island -->
          <ng-container *ngIf="selected.value.state?.name === 'Rhode Island'">
            <app-rhode-island-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-rhode-island-corp-questions>
          </ng-container>
          <!-- West Virgina -->
          <ng-container *ngIf="selected.value.state?.name === 'West Virginia'">
            <app-west-virginia-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-west-virginia-corp-questions>
          </ng-container>
          <!-- Wisconsin -->
          <ng-container *ngIf="selected.value.state?.name === 'Wisconsin'">
            <app-wisconsin-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-wisconsin-corp-questions>
          </ng-container>
        </ng-container>

        <!-- llc questions -->
        <ng-container *ngIf="entityType === 'llc'">
          <!-- California -->
          <ng-container *ngIf="selected.value.state?.name === 'California'">
            <app-california-llc-questions
              [formControl]="selected['controls'].questions"
              [entityType]="entityType"
            ></app-california-llc-questions>
          </ng-container>
          <!-- Connecticut -->
          <ng-container *ngIf="selected.value.state?.name === 'Connecticut'">
            <app-connecticut-llc-questions
              [formControl]="selected['controls'].questions"
              [entityType]="entityType"
            ></app-connecticut-llc-questions>
          </ng-container>
          <!-- Massachusetts -->
          <ng-container *ngIf="selected.value.state?.name === 'Massachusetts'">
            <app-massachusetts-llc-questions
              [formControl]="selected['controls'].questions"
              [entityType]="entityType"
            >
            </app-massachusetts-llc-questions>
          </ng-container>
          <!-- Maine -->
          <ng-container *ngIf="selected.value.state?.name === 'Maine'">
            <app-maine-llc-questions
              [formControl]="selected['controls'].questions"
            ></app-maine-llc-questions>
          </ng-container>
          <!-- New Hampshire -->
          <ng-container *ngIf="selected.value.state?.name === 'New Hampshire'">
            <!-- NH corp and LLC questions are the same -->
            <app-new-hampshire-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-new-hampshire-corp-questions>
          </ng-container>
          <!-- New York -->
          <ng-container *ngIf="selected.value.state?.name === 'New York'">
            <!-- NY corp and LLC questions are the same -->
            <app-new-york-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-new-york-corp-questions>
          </ng-container>
          <!-- West Virginia -->
          <ng-container *ngIf="selected.value.state?.name === 'West Virginia'">
            <!-- WV corp and LLC questions are the same -->
            <app-west-virginia-corp-questions
              [formControl]="selected['controls'].questions"
            ></app-west-virginia-corp-questions>
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
  </div>
</div>
