import { InjectionToken } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

interface Error {
  code: number;
  errorKey: string;
  message: string;
}

export const ERROR_CODE_MAP: { [key: number]: string } = {
  1: 'duplicateEmail',
  2: 'loginFailed',
};

export const defaultErrors = {
  required: (error: string | null) => `This field is required.`,
  emailIsTaken: (error: string | null) => `Email already exists.`,
  notBeforeDate: (error: string | null) => `Please enter a date in the past.`,
  isDuplicate: (error: string | null) =>
    `This field must not match any other fields.`,
  businessNameAndType: (error: string | null) => `This field is required.`,
  email: (error: string | null) => `Please enter an email.`,
  foreignRegistrationStates: (error: string | null) => ``,
  contactInformation: (error: string | null) => ``,
  duplicateEmail: (error: string | null) => `This email is already in use.`,
  loginFailed: (error: string | null) =>
    `Login failed, please check email and password.`,
  minlength: ({ requiredLength }: { requiredLength: number }) =>
    `Please enter at least ${requiredLength} characters.`,
  maxlength: ({ requiredLength }: { requiredLength: number }) =>
    `Please enter at no more than ${requiredLength} characters.`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});

export function setErrors(errors: Error[], form: FormGroup): void {
  errors.forEach((error: any) => {
    const errorKey: string = ERROR_CODE_MAP[error.code];
    form.controls[error.errorKey].setErrors({
      [errorKey]: true,
    });
  });
}
