import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusinessEntityRegisterQuestion } from '@types';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';

interface QuestionResponseCacheItem {
  response: BusinessEntityRegisterQuestion[];
  states: string;
  isDomestic: boolean;
  entityType: string;
}

@Injectable({
  providedIn: 'root',
})
export class BusinessEntityRegisterService {
  stateRegistrationQuestions: QuestionResponseCacheItem[] = [];

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: NotificationService
  ) {}

  checkForCachedResponse(
    states: string[],
    isDomestic: boolean,
    entityType: string
  ) {
    return this.stateRegistrationQuestions.find((value) => {
      if (
        value.entityType === entityType &&
        value.isDomestic === isDomestic &&
        value.states === states.join()
      ) {
        return value;
      }

      return false;
    });
  }

  registerBusinessEntityAndAccount(registration) {
    return this._httpClient
      .post(`${environment.apiUrl}/business-entity`, registration)
      .pipe(
        catchError((error) => {
          console.error('error in registration', error);
          if (error?.error.statusCode === 402 && error.error.message) {
            this._notificationService.showErrorNotification(
              error.error.message
            );
          } else {
            this._notificationService.showErrorNotification(
              'An error occured while trying to register.'
            );
          }

          throw error;
        })
      );
  }

  newStateBusinessRegistration  (registration) {
    return this._httpClient
      .post(`${environment.apiUrl}/business-entity/add-registration`, registration)
      .pipe(
        catchError((error) => {
          console.error('error in registration', error);
          if (error?.error.statusCode === 402 && error.error.message) {
            this._notificationService.showErrorNotification(
              error.error.message
            );
          } else {
            this._notificationService.showErrorNotification(
              'An error occured while trying to register.'
            );
          }
          throw error;
        })
      );
  }
}
