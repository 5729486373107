import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { RegisterStepperComponent } from '../shared/components/register-stepper/register-stepper.component';
import { ConfirmationService } from 'primeng/api';

@Injectable()
export class DeactivateGuard
  implements CanDeactivate<RegisterStepperComponent>
{
  constructor(private confirmationService: ConfirmationService) {}
  canDeact = true;

  canDeactivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.canDeact) {
        resolve(true);
      }

      this.confirmationService.confirm({
        message:
          'Are you sure that you want to navigate away? All information will be lost.',
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => resolve(true),
        reject: () => resolve(false),
      });
    });
  }
}
