import { Component, forwardRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from 'src/app/services/auth.service';
import { BillingService } from 'src/app/services/billing.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-pricing-options',
  templateUrl: './pricing-options.component.html',
  styleUrls: ['./pricing-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PricingOptionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PricingOptionsComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class PricingOptionsComponent implements OnInit {
  @Input() entityType: string;
  @Input() domesticState;
  @Input() set foreignStates(value) {
    this.selectedForeignStates = value.states.map((stateObj) => stateObj.state.id);
  }
  @Input() contactInformation;
  @Input() loginInformation;
  @Input() showError = false;
  @Input() hideEinBullet = false;
  form = new FormGroup({
    optionSelected: new FormControl(null, Validators.required),
    discountCode: new FormControl(null),
    selectedInvoice: new FormControl(null, Validators.required),
    coupon: new FormControl(null),
    totalCost: new FormControl(null),
    discount: new FormControl(null),
  });
  @Output() loadingInvoice = new EventEmitter(false);
  loading = true;
  selectedForeignStates: string[];
  plans: any[] = [];
  loadingPlans = true;
  selectedPlan;
  codeInvalid = false;
  totalCost = 0;

  constructor(
    private _billingService: BillingService,
    private _notificationService: NotificationService,
  ) {}

  get optionSelected() {
    return this.form.controls.optionSelected;
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.showError = false;
      }
      this.onChange(value);
      this.onTouch();
    });

    this._billingService
      .getPriceOptions(
        {
          name: `${this.contactInformation.firstName} ${this.contactInformation.lastName}`,
          email: this.loginInformation.googleUser
            ? this.loginInformation.googleUser.email
            : this.loginInformation.email,
          domesticState: this.domesticState,
          foreignStates: this.selectedForeignStates,
          entityType: this.entityType,
        },
        this.hideEinBullet,
      )
      .subscribe({
        next: (plans) => {
          this.plans = plans;
          this.loadingPlans = false;
          const optionSelected = this.form.get('optionSelected');
          if (optionSelected?.value) {
            const plan = plans.find((invoice) => invoice.label === optionSelected.value.label);
            if (plan) {
              this.selectPlan(plan);
            } else {
              optionSelected.reset();
            }
          }
        },
        error: () => (this.loadingPlans = false),
      });
  }

  getCoupon() {
    this.loading = true;
    const { value } = this.form;
    if (!value.discountCode) return;
    this._billingService.getDiscount(value.selectedInvoice.id, value.discountCode).subscribe({
      next: (data) => {
        if (data) {
          const { coupon, total, error, discount } = data;
          const invoice = this.form.get('selectedInvoice')?.value;
          if (error) {
            this.codeInvalid = true;
            this._notificationService.showErrorNotification(
              error.message || 'Invalid coupon code.',
            );
            this.form.get('coupon')?.setValue(null);
            this.form.get('discount')?.setValue(null);
            if (invoice) {
              this.totalCost = invoice.amount_due + Number(invoice.metadata.registeredAgentFee);
              this.form.get('totalCost')?.setValue(this.totalCost);
            }
          } else {
            if (coupon.valid) {
              this.codeInvalid = false;
              this.form.get('coupon')?.setValue(coupon);
              this.totalCost = total;
              this.form.controls.totalCost.setValue(total);
              this.form.get('discount')?.setValue(discount);
            } else {
              this.form.get('coupon')?.setValue(null);
              this.form.get('discount')?.setValue(null);
              if (invoice) {
                this.totalCost = invoice.amount_due + Number(invoice.metadata.registeredAgentFee);
                this.form.get('totalCost')?.setValue(this.totalCost);
              }
              this._notificationService.showErrorNotification('Invalid coupon code.');
            }
          }
        } else {
          this._notificationService.showErrorNotification('Invalid coupon code.');
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this._notificationService.showErrorNotification('Error retrieving discount.');
        console.log('error in coupon', err);
      },
    });
  }

  selectPlan(plan: any) {
    if (!plan) {
      //Don't allow or just validate discount code??
    }
    this.optionSelected.setValue(plan);
    this.selectedPlan = plan;
    this.loadingInvoice.emit(true);
    this.loading = true;
    this._billingService
      .getInvoice({
        name: `${this.contactInformation.firstName} ${this.contactInformation.lastName}`,
        email: this.loginInformation.googleUser
          ? this.loginInformation.googleUser.email
          : this.loginInformation.email,
        prices: this.selectedPlan.prices,
        label: this.selectedPlan.label,
        promoCode: '',
        invoiceId: this.form.controls.selectedInvoice.value?.id,
      })
      .subscribe(
        (invoice) => {
          this.totalCost = invoice.amount_due + Number(invoice.metadata.registeredAgentFee);
          this.form.controls.totalCost.setValue(this.totalCost);
          if (invoice.description.includes(this.selectedPlan.label)) {
            this.form.get('selectedInvoice')?.setValue(invoice);
            this.loadingInvoice.emit(false);
            this.loading = false;
          }
          if (this.form.get('discountCode')?.value) {
            // populate coupon if it exists
            this.getCoupon();
          }
        },
        (err) => {
          this.loadingInvoice.emit(false);
          this.loading = false;
          this._notificationService.showErrorNotification('Server error. Please try again.');
        },
      );
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { businessNameAndType: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
