<div [formGroup]="form">
  <span class="p-float-label">
    <input id="name" formControlName="name" type="text" pInputText data-qa="name-input" />
    <label for="name">Business Name</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      id="entityType"
      [options]="entityTypes"
      [autoDisplayFirst]="false"
      formControlName="entityType"
      optionLabel="name"
      (onChange)="
        _formService.onEntityTypeChange(entityTypeVal, 'domesticRegistration', 'reset', this)
      "
      (onFocus)="entityTypeVal = form.controls.entityType.value"
    ></p-dropdown>
    <label for="entityType">Entity Type</label>
  </span>

  <ng-container *ngIf="industries | async as industryOptions">
    <span class="p-float-label">
      <p-dropdown
        id="industry"
        [options]="industryOptions"
        formControlName="industry"
        [autoDisplayFirst]="false"
        [filter]="true"
        filterBy="name"
        optionLabel="name"
        optionValue="id"
        [virtualScroll]="true"
        [itemSize]="30"
      >
        <ng-template let-industry pTemplate="item">
          <div class="w-20rem lg:w-28rem xl:w-30rem">
            <div class="white-space-normal">{{ industry.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <label for="industry">Industry</label>
    </span>
  </ng-container>

  <span class="p-float-label">
    <p-inputMask id="ein" formControlName="ein" data-qa="ein-input" mask="99-9999999"></p-inputMask>
    <label for="ein">Federal EIN</label>
  </span>

  <span class="p-float-label">
    <!-- This element should support year selection but currently does not. -->
    <p-calendar
      appendTo="body"
      inputid="qualificationDate"
      formControlName="qualificationDate"
      data-qa="qualificationDate-input"
      [maxDate]="maxDate"
      [defaultDate]="defaultDate"
      class="w-full"
    ></p-calendar>
    <label for="qualificationDate">What is your qualification date?</label>
  </span>
</div>
