import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EntityType } from '@types';
import { map } from 'rxjs/operators';
import { ConstantsService } from 'src/app/services/constants.service';
import { notBeforeDate } from 'src/app/shared/validators';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactInformationComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactInformationComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class ContactInformationComponent implements OnInit {
  @Input() entityType: EntityType;
  form = new FormGroup({
    title: new FormControl(null, Validators.required),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    streetAddress1: new FormControl('', Validators.required),
    streetAddress2: new FormControl(''),
    state: new FormControl(null, Validators.required),
    city: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    ssn: new FormControl('', Validators.required),
    dateOfBirth: new FormControl('', [Validators.required, notBeforeDate]),
    noSSN: new FormControl(false),
  });

  maxBirthdate = new Date();
  defaultBirthDate = new Date('2000-01-02'); // January 2, 2000, default birth date
  states = this._constants.states;
  titles = this._constants.titles.pipe(
    map((titles) => titles.filter((title) => title.entityType === this.entityType)),
  );
  noSSN = false;

  selectOptions = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ];

  constructor(private _constants: ConstantsService) {}

  ngOnInit(): void {
    if (this.entityType === 'corporation') {
      this.form.addControl('isDirector', new FormControl(null, Validators.required));
    }
    this.form
      .get('noSSN')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((noSSN) => {
        if (noSSN) {
          this.form.get('ssn')?.disable();
          this.form.get('ssn')?.removeValidators(Validators.required);
        } else {
          if (!this.form.disabled) {
            this.form.get('ssn')?.addValidators(Validators.required);
            this.form.get('ssn')?.enable();
          }
        }
      });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    if (value) {
      this.form.patchValue({
        ...value,
        dateOfBirth: value.dateOfBirth ? new Date(value.dateOfBirth) : '',
      });
    }
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { contactInformation: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
