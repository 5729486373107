import { Component, forwardRef, OnInit, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
  FormGroupDirective,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, pairwise } from 'rxjs/operators';
import { ENTITY_TYPES } from 'src/app/constants';
import { ConstantsService } from 'src/app/services/constants.service';
import CustomValidators from 'src/app/utils/validators';
import { isEqual } from 'lodash';
import { FormService } from 'src/app/services/form.service';
@Component({
  selector: 'app-business-name-and-type',
  templateUrl: './business-name-and-type.component.html',
  styleUrls: ['./business-name-and-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BusinessNameAndTypeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BusinessNameAndTypeComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class BusinessNameAndTypeComponent implements OnInit {
  // All to lower case
  form = new FormGroup({
    nameOption1: new FormControl('', [Validators.required, this.duplicateName]),
    nameOption2: new FormControl('', [Validators.required, this.duplicateName]),
    nameOption3: new FormControl('', [this.duplicateName]),
    entityType: new FormControl(null, [Validators.required]),
    industry: new FormControl(null, [Validators.required]),
  });

  entityTypes = ENTITY_TYPES;
  entityTypeVal: object | null = null;
  industries = this._constants.industries;
  @Output() reset = new EventEmitter();

  constructor(
    private _constants: ConstantsService,
    private rootFormGroup: FormGroupDirective, //used by FormService
    public _formService: FormService,
  ) {}

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        pairwise(),
        distinctUntilChanged((prev, val) => isEqual(prev, val)),
      )
      .subscribe(([prev, value]) => {
        this.validateNameFields(prev, value);
        this.onChange(value);
        this.onTouch();
      });
  }

  duplicateName(control: AbstractControl) {
    const nameOption1 = control.parent?.get('nameOption1');
    const nameOption2 = control.parent?.get('nameOption2');
    const nameOption3 = control.parent?.get('nameOption3');
    let isDuplicate;
    if (control === nameOption1) {
      isDuplicate =
        control.value.toLowerCase() === nameOption2?.value.toLowerCase() ||
        control.value.toLowerCase() === nameOption3?.value.toLowerCase();
    }
    if (control === nameOption2) {
      isDuplicate =
        control.value.toLowerCase() === nameOption1?.value.toLowerCase() ||
        control.value.toLowerCase() === nameOption3?.value.toLowerCase();
    }
    if (control === nameOption3) {
      isDuplicate =
        control.value.toLowerCase() === nameOption1?.value.toLowerCase() ||
        control.value.toLowerCase() === nameOption2?.value.toLowerCase();
    }

    return isDuplicate ? { isDuplicate } : null;
  }

  validateNameFields(prev: any, value: any) {
    const nameOption1 = this.form.get('nameOption1');
    const nameOption2 = this.form.get('nameOption2');
    const nameOption3 = this.form.get('nameOption3');
    if (prev.nameOption1 !== value.nameOption1) {
      if (nameOption2?.touched) {
        nameOption2?.updateValueAndValidity();
      }
      if (nameOption3?.touched) {
        nameOption3?.updateValueAndValidity();
      }
    }
    if (prev.nameOption2 !== value.nameOption2) {
      if (nameOption1?.touched) {
        nameOption1?.updateValueAndValidity();
      }
      if (nameOption3?.touched) {
        nameOption3?.updateValueAndValidity();
      }
    }
    if (prev.nameOption3 !== value.nameOption3) {
      if (nameOption1?.touched) {
        nameOption1?.updateValueAndValidity();
      }
      if (nameOption2?.touched) {
        nameOption2?.updateValueAndValidity();
      }
    }
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { businessNameAndType: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
