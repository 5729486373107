<form [formGroup]="form">
  <ng-container *ngIf="states | async as stateOptions">
    <span class="p-float-label">
      <p-dropdown
        id="domesticState"
        [options]="stateOptions"
        formControlName="domesticState"
        optionLabel="name"
        optionValue="id"
        [autoDisplayFirst]="false"
      ></p-dropdown>
      <label for="domesticState">Domestic Registration State</label>
    </span>
  </ng-container>
  <ng-container *ngIf="form.value.domesticState && entity === 'corporation'">
    <hr />
    <h3>Par Value and Shares Authorized</h3>
    <span class="p-float-label">
      <input
        [formControl]="
          form.controls.questions['controls'][0]['controls'].answer
        "
        id="parValue"
        type="number"
        pInputText
      />
      <label for="parValue">{{
        form.controls.questions["controls"][0].value.question
      }}</label>
    </span>

    <span class="p-float-label">
      <input
        [formControl]="
          form.controls.questions['controls'][1]['controls'].answer
        "
        id="numberOfShares"
        type="number"
        pInputText
      />
      <label for="numberOfShares">{{
        form.controls.questions["controls"][1].value.question
      }}</label>
    </span>
  </ng-container>
</form>
