<div [formGroup]="form">
  <div class="grid">
    <div class="col-6">
      <span class="p-float-label">
        <input
          id="firstName"
          formControlName="firstName"
          type="text"
          data-qa="firstName-input"
          pInputText
        />
        <label for="password">First Name</label>
      </span>
    </div>
    <div class="col-6">
      <span class="p-float-label">
        <input
          id="lastName"
          formControlName="lastName"
          type="text"
          data-qa="lastName-input"
          pInputText
        />
        <label for="lastName">Last Name</label>
      </span>
    </div>
  </div>
  <ng-container *ngIf="titles | async as titleOptions">
    <span class="p-float-label">
      <p-dropdown
        id="title"
        [options]="titleOptions"
        [autoDisplayFirst]="false"
        formControlName="title"
        optionLabel="name"
        optionValue="id"
      ></p-dropdown>
      <label for="title">Business Title</label>
    </span>
  </ng-container>
  <span class="p-float-label">
    <input
      id="streetAddress1"
      formControlName="streetAddress1"
      type="text"
      data-qa="streetAddress1-input"
      pInputText
    />
    <label for="streetAddress1">Street Address 1</label>
  </span>

  <span class="p-float-label">
    <input
      id="streetAddress2"
      formControlName="streetAddress2"
      type="text"
      data-qa="streetAddress2-input"
      pInputText
    />
    <label for="streetAddress2">Street Address 2</label>
  </span>

  <div class="grid">
    <div class="col-4">
      <span class="p-float-label">
        <input
          id="city"
          formControlName="city"
          type="text"
          data-qa="city-input"
          pInputText
        />
        <label for="city">City</label>
      </span>
    </div>
    <div class="col-4">
      <ng-container *ngIf="states | async as stateOptions">
        <span class="p-float-label">
          <p-dropdown
            id="state"
            [autoDisplayFirst]="false"
            [options]="stateOptions"
            formControlName="state"
            optionLabel="name"
            optionValue="id"
          ></p-dropdown>
          <label for="state">State</label>
        </span>
      </ng-container>
    </div>
    <div class="col-4">
      <span class="p-float-label">
        <input
          id="postalCode"
          formControlName="postalCode"
          type="text"
          data-qa="postalCode-input"
          pInputText
        />
        <label for="postalCode">Postal Code</label>
      </span>
    </div>
  </div>
</div>
