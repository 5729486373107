import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

if (environment.production || environment.test) {
  Sentry.init({
    dsn: 'https://d7ac6e4f6c1643c78355efb88ca2cdfd@o354533.ingest.sentry.io/6099351',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'https://app.integratedstack.com',
          'https://test.integratedstack.com',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: environment.production ? 0.2 : 1.0,
    environment: environment.production ? 'production' : 'test',
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
