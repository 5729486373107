import { Component, forwardRef, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-new-york-corp-questions',
  templateUrl: './new-york-corp-questions.component.html',
  styleUrls: ['./new-york-corp-questions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewYorkCorpQuestionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NewYorkCorpQuestionsComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class NewYorkCorpQuestionsComponent implements OnInit {
  form = new FormGroup({
    0: new FormGroup({
      question: new FormControl(
        `The county within the state in which the office of the corporation is to be located*:`
      ),
      answer: new FormControl('', Validators.required),
    }),
  });
  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
      this.onTouch();
    });
    setTimeout(() => {
      this.form.updateValueAndValidity();
    }, 0);
  }

  /**
   * Control Value Accessor Interface
   */
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this.form.patchValue(value);
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { foreignRegistrationStates: true };
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
