<div [formGroup]="form">
  <div class="mt-3">
    <h3>West Virginia</h3>
  </div>
  <div [formGroupName]="0" class="mt-3">
    <label for="q-0">
      {{ form.controls[0].value.question }}
    </label>
    <input
      id="q-0"
      [formControl]="form.controls[0]['controls'].answer"
      type="number"
      pInputText
    />
  </div>
  <div [formGroupName]="1" class="mt-3">
    <label for="q-1">
      {{ form.controls[1].value.question }}
    </label>
    <p-selectButton
      id="q-1"
      [options]="selectOptions"
      optionLabel="name"
      optionValue="value"
      [formControl]="form.controls[1]['controls'].answer"
    ></p-selectButton>
  </div>
  <div [formGroupName]="2" class="mt-3">
    <label for="q-2">
      {{ form.controls[2].value.question }}
    </label>

    <p-selectButton
      id="q-2"
      [options]="selectOptions"
      optionLabel="name"
      optionValue="value"
      [formControl]="form.controls[2]['controls'].answer"
    ></p-selectButton>
  </div>
  <div [formGroupName]="3" class="mt-3">
    <label for="q-3">
      {{ form.controls[3].value.question }}
    </label>
    <p-selectButton
      id="q-3"
      [options]="selectOptions"
      optionLabel="name"
      optionValue="value"
      [formControl]="form.controls[3]['controls'].answer"
    ></p-selectButton>
  </div>
  <ng-container *ngIf="form.controls[3].value.answer">
    <div [formGroupName]="4" class="mt-3">
      <label for="q-4">
        {{ form.controls[4].value.question }}
      </label>
      <input
        id="q-4"
        [formControl]="form.controls[4]['controls'].answer"
        type="number"
        pInputText
      />
    </div>
    <div [formGroupName]="5" class="mt-3">
      <label for="q-4">
        {{ form.controls[5].value.question }}
      </label>
      <input
        id="q-4"
        [formControl]="form.controls[5]['controls'].answer"
        type="number"
        pInputText
      />
    </div>
  </ng-container>
</div>
