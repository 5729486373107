<div [formGroup]="form">
  <div class="mt-3">
    <h3>Massachusetts</h3>
  </div>
  <div class="flex flex-column">
    <label for="q-0">{{ form.controls[1].value.question }}</label>

    <p-radioButton
      class="mt-3"
      value="none"
      label="None"
      [formControl]="form.controls[0]['controls'].answer"
      id="q-0"
    ></p-radioButton>
    <p-radioButton
      class="mt-3"
      value="provided"
      label="Provided"
      [formControl]="form.controls[0]['controls'].answer"
      id="q-0"
    ></p-radioButton>
  </div>
  <ng-container *ngIf="form.controls[0].value.answer === 'provided'">
    <div
      class="mt-3"
      *ngFor="let address of form.controls[1]['controls'].answer['controls']; let i = index"
    >
      <app-address-input [entityType]="entityType" [formControl]="address"></app-address-input>
      <div class="flex mt-3 justify-content-end">
        <button
          pButton
          class="p-button-outlined mr-1"
          label="Remove Officer"
          *ngIf="i > 0"
          (click)="removeAdditionalAddress(i)"
        ></button>
        <button pButton label="Add Additional Officer" (click)="addAdditionalAddress()"></button>
      </div>
    </div>
  </ng-container>
</div>
