import { Injectable } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import posthog from 'posthog-js'

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {

    constructor(
        private googleAnalytics: Analytics,
    ) {
        posthog.init(
            'phc_AVBDPymB2uU9dudXMagdQVThJ8R8G6q7cHbesFJ7HL8',
            {
                api_host: 'https://app.posthog.com',
                session_recording: {
                    maskAllInputs: true,
                },
                autocapture: true,
            }
        );
    }            
    
    /**
     * Logs an event to Google Analytics and PostHog
     *
     * @param   {string}  eventName    Name of event
     * @param   {any}     eventParams  Arbitrary event parameters
     *
     */
    public logEvent(eventName: string, eventParams: object) {

        // send to google analytics
        logEvent(this.googleAnalytics, eventName, eventParams);

        // Prep for posthog
        const event_name = eventName;
        const properties = eventParams;

        // Send to Posthog
        posthog.capture(event_name, properties);
    }

}
